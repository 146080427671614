import { authorizationService } from "@/services";

// import mapGetters from "vuex";
export default {
  install(Vue) {

    Vue.allowed = authorizationService.allowed;
    Vue.hasRole = authorizationService.hasRole;
    Vue.getUserInfo = authorizationService.getUserInfo;
    Vue.authorized = authorizationService.authorized;
    Vue.generateStoreTopics = authorizationService.generateStoreTopics;

    Vue.mixin({
      methods: {
        $hasRole(roleName) {
          return authorizationService.hasRole(roleName);
        },
        $allowed(permission) {
          return authorizationService.allowed(permission);
        },
        $getUserInfo() {
          return authorizationService.getUserInfo();
        },
        $authorized() {
          return authorizationService.authorized();
        },
        $generateStoreTopics(storeId) {
          return authorizationService.generateStoreTopics(storeId);
        },
      },
    });
  },
};


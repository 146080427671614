export const OPEN_STORE = "openStore";
export const LOAD_ASSIGNED_STORES = "loadAssignedStores"
export const LOAD_ASSIGNED_STORES_COMPLETED = "loadAssignedStoresCompleted"
export const LOAD_STORE = "loadStore"
export const LOAD_STORE_COMPLETED = "loadStoreCompleted"
export const RELOAD_STORES = "loadStores"
export const RELOAD_STORES_COMPLETED = "loadStoresCompleted"
export const CLEAR_STORES = "clearStores";
export const IS_LOCATION_IN_AOS = "isLocationInAos";

// getters
export const GET_OPEN_STORE = "getOpenStore";
export const GET_OPEN_STORE_CURRENCY = "getOpenStoreCurrency";
export const GET_OPEN_STORE_ID = "getOpenStoreId";
export const GET_ASSIGNED_STORES = "getAssignedStores"
export const GET_STORE_BY_ID = "getStoreById";
export const GET_STORE_BY_ID_AND_REGION_ID = "getStoreByIdAndRegionId";

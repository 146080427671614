import { apiActions } from "@/services";
import * as actionTypes from "../actions/type.orders";
import crud from "../modules/crud";
import _ from "lodash";

const orders = crud("Order", "orders");

const state = {
  ...orders.state,

  myOrders: [],
  myOrderSelection: [],
  myOrdersLoading: false,
  myOrdersLoaded: false,

  activeOrders: [],
  activeOrdersLoading: false,
  activeOrdersLoaded: false,

  driverActiveOrders: [],
  driverActiveOrdersLoading: false,
  driverActiveOrdersLoaded: false,

  location: null,
};

// getters
const getters = {
  ...orders.getters,

  [actionTypes.GET_LATEST_LOCATION]: (state) => () => {
    return state.location;
  },
  [actionTypes.GET_MY_ORDER_BY_ID]: (state) => (orderId) => {
    return state.myOrders.find((f) => f.id === orderId);
  },
  [actionTypes.GET_MY_ORDER_BY_UUID]: (state) => (value) => {
    return state.myOrders.find((f) => f.uuid === value);
  },
  [actionTypes.GET_MY_ORDERS](state) {
    return state.myOrders;
  },
  [actionTypes.GET_ACTIVE_ORDERS](state) {
    return state.activeOrders;
  },
  [actionTypes.GET_SELECTED_ORDER](state) {
    return state.myOrderSelection;
  },
  [actionTypes.GET_ACTIVE_ORDERS_BY_STATUS]: (state) => (status) => {
    if (status instanceof Array) {
      return state.activeOrders.filter((f) => status.includes(f.activeTrackStatus));
    } else {
      return state.activeOrders.filter((f) => f.activeTrackStatus === status);
    }
  },
  [actionTypes.GET_ACTIVE_ORDERS_BY_DRIVER]: (state) => (driverUuid) => {
    return state.activeOrders.filter((f) => f.driverUuid === driverUuid);
  },
  [actionTypes.GET_DRIVER_ACTIVE_ORDERS](state) {
    return state.driverActiveOrders;
  },
};

// actions
const actions = {
  ...orders.actions,

  async [actionTypes.CONFIRM_ORDER_ITEMS]({ commit, dispatch }, params) {
    const { storeId, orderUuid } = params;

    return apiActions.update(
      `orders/${orderUuid}/confirm`,
      null,
      null,
      null,
      {
        silent: true,
        skipCompletedMutation: true,
        onSuccess: () => {
          if (storeId) {
            dispatch(actionTypes.LOAD_ACTIVE_ORDERS, storeId);
          }
        }        
      }
    );
  },

  async [actionTypes.COLLECT_ORDER_CASH]({ commit, dispatch }, params) {
    const { storeId, orderUuid } = params;

    return apiActions.update(
      `orders/${orderUuid}/collect`,
      null,
      null,
      null,
      {
        silent: true,
        skipCompletedMutation: true,
        onSuccess: () => {
          if (storeId) {
            dispatch(actionTypes.LOAD_ACTIVE_ORDERS, storeId);
          }
        }        
      }
    );
  },

  async [actionTypes.RETURN_ORDER]({ commit, dispatch }, params) {
    const { storeId, orderUuid } = params;

    return apiActions.update(
      `orders/${orderUuid}/returned`,
      null,
      null,
      null,
      {
        silent: true,
        skipCompletedMutation: true,
        onSuccess: () => {
          if (storeId) {
            dispatch(actionTypes.LOAD_ACTIVE_ORDERS, storeId);
          }
        }        
      }
    );
  },

  async [actionTypes.UPDATE_ORDER_LOCATION]({ commit }, params) {
    return apiActions.update(
      `orders/location`,
      params,
      commit,
      actionTypes.UPDATE_ORDER_LOCATION,
      {
        silent: true,
        skipCompletedMutation: true,
      }
    );
  },
  async [actionTypes.LOAD_MY_ORDERS]({ commit }) {
    return apiActions.load(
      "orders/customer",
      null,
      commit,
      actionTypes.LOAD_MY_ORDERS);
  },
  async [actionTypes.LOAD_ACTIVE_ORDERS]({ commit }, storeId) {
    return apiActions.load(
      `orders/store/${storeId}/active`,
      null,
      commit,
      actionTypes.LOAD_ACTIVE_ORDERS
    );
  },
  async [actionTypes.LOAD_DRIVER_ACTIVE_ORDERS]({ commit }) {
    return apiActions.load(
      "orders/driver/active",
      null,
      commit,
      actionTypes.LOAD_DRIVER_ACTIVE_ORDERS
    );
  },
  async [actionTypes.UPDATE_ORDER_STATUS]({ commit, dispatch }, params) {
    let storeId = null;
    let status = null;
    let driverUuid = null;
    let multi = params instanceof Array && params.length > 0;

    let url = null;
    if (!multi) {
      storeId = params.storeId;
      status = params.status;
      driverUuid = params.driverUuid;

      if ([
        actionTypes.STATUS_CANCELLED,
        actionTypes.STATUS_NOT_ACCEPTED,
        actionTypes.STATUS_WAITING,
        actionTypes.STATUS_PREPARING
      ].includes(status)) {
        const { orderUuid } = params;
        url = `orders/${orderUuid}/preloading/${status}`;
      } else if (actionTypes.STATUS_LOADING == status) {
        const { orderUuid, driverUuid } = params;
        url = `orders/${orderUuid}/loading/${driverUuid}`;
      } else if ([
        actionTypes.STATUS_DISPATCHED,
        actionTypes.STATUS_DELIVERY,
        actionTypes.STATUS_NEAR,
        actionTypes.STATUS_DELIVERED,
        actionTypes.STATUS_RETURN
      ].includes(status)) {
        const { orderUuid } = params;
        url = `orders/${orderUuid}/delivery/${status}`;
      } else if (actionTypes.STATUS_SWITCH === status && !!params.driverUuid) {
        const { orderUuid, driverUuid } = params;
        url = `orders/${orderUuid}/switch/${driverUuid}`;
      }

      return apiActions.update(url, null, commit, null, {
        successMessage: `messages.orderTrackUpdated${status.replace(" ", "_")}`,
        // not need to manually reload, it happens through REACTIVITY techinque
        // onSuccess: () => {
        //   if (storeId) {
        //     dispatch(actionTypes.LOAD_ACTIVE_ORDERS, storeId);
        //   }
        // }
      });
    } else {
      // the only scenario for multi is when we are calling back all orders in a dirver dispatch to loading
      storeId = params[0].storeId;
      status = params[0].status;
      driverUuid = params[0].driverUuid;

      url = `orders/callback/${driverUuid}`;
      const uuids = params.map(m => m.orderUuid);
      return apiActions.update(url, uuids, commit, null, {
        successMessage: `messages.orderTrackUpdated${status.replace(" ", "_")}`,
        onSuccess: () => {
          if (storeId) {
            dispatch(actionTypes.LOAD_ACTIVE_ORDERS, storeId);
          }
        }
      });
    }
  },
  [actionTypes.SET_SELECTED_ORDER]({ commit }, item) {
    commit(actionTypes.SET_SELECTED_ORDER, item);
  },
  [actionTypes.CLEAR_SELECTED_ORDER]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_ORDER);
  },
  [actionTypes.COLLAPSE_ALL]({ commit }) {
    commit(actionTypes.COLLAPSE_ALL);
  },
  [actionTypes.EXPAND_ALL]({ commit }) {
    commit(actionTypes.EXPAND_ALL);
  },
};

// mutations
const mutations = {
  ...orders.mutations,

  [actionTypes.UPDATE_ORDER_LOCATION](state, payload) {
    state.location = payload.data;
  },
  [actionTypes.LOAD_MY_ORDERS](state) {
    state.myOrdersLoading = true;
  },
  [actionTypes.LOAD_MY_ORDERS_COMPLETED](state, payload) {
    state.myOrdersLoading = false;
    state.myOrdersLoaded = payload.success;
    state.myOrders = payload.data;
  },
  [actionTypes.LOAD_ACTIVE_ORDERS](state) {
    state.myOrdersLoading = true;
  },
  [actionTypes.LOAD_ACTIVE_ORDERS_COMPLETED](state, payload) {
    state.activeOrdersLoading = false;
    state.activeOrdersLoaded = payload.success;
    state.activeOrders = payload.data;
  },
  [actionTypes.LOAD_DRIVER_ACTIVE_ORDERS](state) {
    state.driverActiveOrdersLoading = true;
  },
  [actionTypes.LOAD_DRIVER_ACTIVE_ORDERS_COMPLETED](state, payload) {
    state.driverActiveOrdersLoading = false;
    state.driverActiveOrdersLoaded = payload.success;
    state.driverActiveOrders = payload.data;
  },
  [actionTypes.SET_SELECTED_ORDER](state, payload) {
    state.myOrderSelection = payload;
  },
  [actionTypes.CLEAR_SELECTED_ORDER](state) {
    state.myOrderSelection = null;
  },
  [actionTypes.COLLAPSE_ALL](state) {
    state.activeOrders = state.activeOrders.map((m) => ({
      ...m,
      collapsed: true,
    }));
  },
  [actionTypes.EXPAND_ALL](state) {
    state.activeOrders = state.activeOrders.map((m) => ({
      ...m,
      collapsed: false,
    }));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

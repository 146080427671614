import * as actionTypes from "../actions/type.counters";

const state = {
  scannedOrders: [],
};

const getters = {
  [actionTypes.GET_SCANNED_ORDERS] (state) {
    return state.scannedOrders;
  },
  [actionTypes.GET_SCAN_QUNATITY]: (state) => (orderUuid, productUuid) => {
    const order = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    const items = order && order.items ? order.items : []; 
    const item = items.find((f) => f.productUuid === productUuid);
    return item ? item.scans : 0;
  },
  [actionTypes.GET_SCANNED_ITEMS]: (state) => (orderUuid) => {
    const order = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    return order ? order.items : [];
  },
  [actionTypes.GET_ACTIVE_SCAN]: (state) => (orderUuid) => {
    const order = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    return order ? order.activeScan : null;
  },
};

const actions = {
  [actionTypes.SCAN_ITEM]({ commit }, { orderUuid, item }) {
    commit(actionTypes.SCAN_ITEM, { orderUuid, item });
  },
  [actionTypes.RESET_ITEM]({ commit }, { orderUuid, item }) {
    commit(actionTypes.RESET_ITEM, { orderUuid, item });
  },
  [actionTypes.SELECT_ITEM]({ commit }, { orderUuid, item }) {
    commit(actionTypes.SELECT_ITEM, { orderUuid, item });
  },
  [actionTypes.CLEAR_SCANNED_ORDER]({ commit }, orderUuid) {
    commit(actionTypes.CLEAR_SCANNED_ORDER, orderUuid);
  },
  [actionTypes.CLEAR_SCANNED_ORDERS]({ commit }) {
    commit(actionTypes.CLEAR_SCANNED_ORDERS);
  },
};

const mutations = {
  [actionTypes.SCAN_ITEM](state, { orderUuid, item }) {
    let scannedItem = null;
    const activeOrder = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    if(activeOrder) {
      scannedItem = activeOrder.items.find((i) => i.productUuid === item.productUuid);
      if (!scannedItem) {
        scannedItem = { id: item.id, productUuid: item.productUuid, quantity: item.quantity, scans: 1 };
        activeOrder.items.push(scannedItem);
      } else {
        scannedItem.scans++;
      }
      activeOrder.activeScan = scannedItem;
    } else {
      scannedItem = { id: item.id, productUuid: item.productUuid, quantity: item.quantity, scans: 1 };
      state.scannedOrders.push({
        orderUuid,
        activeScan: scannedItem,
        items: [scannedItem],
      });
    }
  },
  [actionTypes.RESET_ITEM](state, {orderUuid, item}) {
    const activeOrder = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    if(activeOrder) {
      activeOrder.items = activeOrder.items.filter((i) => i.productUuid !== item.productUuid);
      if(activeOrder.activeScan && activeOrder.activeScan.productUuid === item.productUuid) {
        activeOrder.activeScan = null;
      }
    }
  },
  [actionTypes.SELECT_ITEM](state, {orderUuid, item}) {
    const activeOrder = state.scannedOrders.find((f) => f.orderUuid === orderUuid);
    if(activeOrder) {
      const activeScan = activeOrder.items.find((i) => i.productUuid === item.productUuid);
      if(activeScan) {
        activeOrder.activeScan = activeScan;
      }
    }
  },
  [actionTypes.CLEAR_SCANNED_ORDER](state, payload) {
    state.scannedOrders = state.scannedOrders.filter((f) => f.orderUuid !== payload);
  },
  [actionTypes.CLEAR_SCANNED_ORDERS](state) {
    state.scannedOrders = [];
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

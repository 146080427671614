import moment from 'moment';

const validationHelpers = {
  "Required": (v, validation) => !!v || validation.message,
  "Min": (v, validation) => (v && v.length >= validation.value) || validation.message,
  "Max": (v, validation) => (v && v.length <= validation.value) || validation.message,
  "Email": (v, validation) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || validation.message,
  "Numeric": (v, validation) => /^\d+$/.test(v) || validation.message,
  "Decimal": (v, validation) => /^-?\d*(\.\d+)?$/.test(v) || validation.message,
  "Pattern": (v, validation) => (!v || new RegExp(validation.value).test(v)) || validation.message,
  "After": (v, validation) =>
    (v && moment(v, validation.format).isAfter(moment(validation.value, validation.format))) ||
    validation.message,
  "Before": (v, validation) =>
    (v && moment(v, validation.format).isBefore(moment(validation.value, validation.format))) ||
    validation.message,
  "SameOrAfter": (v, validation) =>
    (v && moment(v, validation.format).isSameOrAfter(moment(validation.value, validation.format))) ||
    validation.message,
  "SameOrBefore": (v, validation) =>
    (v && moment(v, validation.format).isSameOrBefore(moment(validation.value, validation.format))) ||
    validation.message,
  "GreaterThan": (v, validation) =>
    (v && parseFloat(v) > parseFloat(validation.value)) || validation.message,
  "GreaterThanOrEqual": (v, validation) =>
    (v && parseFloat(v) >= parseFloat(validation.value)) || validation.message,
  "LessThan": (v, validation) =>
    (v && parseFloat(v) < parseFloat(validation.value)) || validation.message,
  "LessThanOrEqual": (v, validation) =>
    (v && parseFloat(v) <= parseFloat(validation.value)) || validation.message,
};

const getValidationRules = (validations) => {
  if(!validations) {
    return [];
  }

  return validations.map((validation) => {
    const validationFunction = validationHelpers[validation.rule];
    if (!validationFunction) {
      throw new Error(`Unknown validation rule type: ${validation.rule}`);
    }
    return (v) => validationFunction(v, validation);
  });
};

const FormValidation = {
  install(Vue) {
    Vue.prototype.$getValidationRules = getValidationRules;
  },
};

export default FormValidation;

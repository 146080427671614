import * as actionTypes from "../actions/type.ui";
import { Plugins } from "@capacitor/core";
import moment from "moment";

const { Device, StatusBar } = Plugins;

const state = {
  language: "en",
  theme: "default",
  themes: [
    {
      name: "default",
      light: {
        primary: "#003459",
        secondary: "#00ff9f",
        darkSecondary: "#07cc89",
        accent: "#FFFFFF",
        error: "#ff4721",
        warning: "#ffdf2d",
        info: "#40bcd4",
        success: "#07cc89",
      },
      bgLogoClass: "bg-logo",
    },
    {
      name: "admin",
      light: {
        primary: "#393E46",
        secondary: "#90E0EF",
        darkSecondary: "#00B4D8",
        accent: "#DAFFFB",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#00bcd4",
        success: "#00c688",
      },
      bgLogoClass: "bg-logo bg-logo-blue",
    },
    {
      name: "merchant",
      light: {
        primary: "#392467",
        secondary: "#E3F4F4",
        darkSecondary: "#8dd7d7",
        accent: "#E3F4F4",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#00bcd4",
        success: "#00c688",
      },
      bgLogoClass: "bg-logo bg-logo-purple",
    },
    {
      name: "driver",
      light: {
        primary: "#16236e",
        secondary: "#54c8fd",
        darkSecondary: "#039ee9",
        accent: "#b1e6fe",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#54c8fd",
        success: "#00c688"
      },
      bgLogoClass: "bg-logo bg-logo-blue",
    },
  ],
  activeDialog: null,
  activeNotification: { show: false, timeout: 4000, alert: false },
  menuBadges: { offers: [], orders: [] },
  loading: { state: false, message: "" },
  offline: false,
  isMobile: false,
  reactivityParams: [],
  viewData: {},
};

const getters = {
  [actionTypes.GET_COLOR]: (state) => (name) => {
    const current = state.themes.find(f => f.name === state.theme);
    return current.light[name];
  },
  [actionTypes.GET_LANGUAGE](state) {
    return state.language;
  },
  [actionTypes.GET_LOADING_STATE](state) {
    return state.loading.state;
  },
  [actionTypes.GET_LOADING_MESSAGE](state) {
    return state.loading.message;
  },
  [actionTypes.GET_ACTIVE_DIALOG](state) {
    return state.activeDialog;
  },
  [actionTypes.GET_ACTIVE_NOTIFICATION](state) {
    return state.activeNotification;
  },
  [actionTypes.GET_MENU_BADGES]: (state) => (resource) => {
    return state.menuBadges[resource];
  },
  [actionTypes.GET_MENU_BADGES_COUNTER]: (state) => (resource) => {
    return state.menuBadges[resource] ? state.menuBadges[resource].length : 0;
  },
  [actionTypes.GET_OFFLINE]: (state) => (resource) => {
    return state.offline;
  },
  [actionTypes.IS_MOBILE](state) {
    return state.isMobile;
  },
  [actionTypes.GET_REACTIVITY_PARAMS](state) {
    return state.reactivityParams;
  },
  [actionTypes.GET_VIEW_DATA](state) {
    return state.viewData;
  },
};

const actions = {
  [actionTypes.SET_HOME_PAGE]({ commit }, page) {
    commit(actionTypes.SET_HOME_PAGE, page);
  },
  [actionTypes.SET_LOADING_STATE]({ commit }, message) {
    commit(actionTypes.SET_LOADING_STATE, message);
  },
  [actionTypes.CLEAR_LOADING_STATE]({ commit }) {
    commit(actionTypes.CLEAR_LOADING_STATE);
  },
  [actionTypes.SET_LANGUAGE]({ commit }, lang) {
    commit(actionTypes.SET_LANGUAGE, lang);
  },
  [actionTypes.SET_THEME]({ commit }, theme) {
    commit(actionTypes.SET_THEME, theme);
  },
  [actionTypes.SHOW_FEEDBACK]({ commit }, notification) {
    commit(actionTypes.SHOW_FEEDBACK, notification);
  },
  [actionTypes.DISMISS_FEEDBACK]({ commit }) {
    commit(actionTypes.DISMISS_FEEDBACK);
  },
  [actionTypes.SHOW_DIALOG]({ commit }, dialog) {
    commit(actionTypes.SHOW_DIALOG, dialog);
  },
  [actionTypes.REFRESH_DIALOG]({ commit }) {
    commit(actionTypes.REFRESH_DIALOG);
  },
  [actionTypes.CLOSE_DIALOG]({ commit }) {
    commit(actionTypes.CLOSE_DIALOG);
  },
  [actionTypes.ADD_TO_MENU_BADGE]({ commit }, params) {
    const { resource, keys } = params;
    commit(actionTypes.ADD_TO_MENU_BADGE, { resource, keys });
  },
  [actionTypes.CLEAR_MENU_BADGE]({ commit }, params) {
    const { resource } = params;
    commit(actionTypes.CLEAR_MENU_BADGE, { resource });
  },
  [actionTypes.SET_OFFLINE]({ commit }, offline) {
    commit(actionTypes.SET_OFFLINE, offline);
  },
  [actionTypes.SET_IS_MOBILE]({ commit }) {
    Device.getInfo().then((value) => commit(actionTypes.SET_IS_MOBILE, value));
  },
  [actionTypes.SET_REACTIVITY_PARAMS]({ commit }, params) {
    commit(actionTypes.SET_REACTIVITY_PARAMS, params);
  },
  [actionTypes.CLEAR_REACTIVITY_PARAMS]({ commit }) {
    commit(actionTypes.CLEAR_REACTIVITY_PARAMS);
  },
  [actionTypes.REFRESH_REACTIVITY_PARAMS]({ commit }) {
    commit(actionTypes.REFRESH_REACTIVITY_PARAMS);
  },
  [actionTypes.SET_VIEW_DATA]({ commit }, params) {
    commit(actionTypes.SET_VIEW_DATA, params);
  },
  [actionTypes.CLEAR_VIEW_DATA]({ commit }, params) {
    commit(actionTypes.CLEAR_VIEW_DATA, params);
  },
};

const mutations = {
  [actionTypes.SET_LOADING_STATE](state, payload) {
    state.loading = { state: true, message: payload };
  },
  [actionTypes.CLEAR_LOADING_STATE](state) {
    state.loading = { state: false, message: "" };
  },
  [actionTypes.SET_LANGUAGE](state, payload) {
    state.lang = payload;
  },
  [actionTypes.SET_THEME](state, payload) {
    state.theme = payload;
  },
  [actionTypes.SHOW_FEEDBACK](state, payload) {
    state.activeNotification = { timeout: 4000, ...payload, show: true };
  },
  [actionTypes.DISMISS_FEEDBACK](state) {
    state.activeNotification = { show: false, timeout: 4000, alert: false };
  },
  [actionTypes.SHOW_DIALOG](state, payload) {
    payload.show = true;
    state.activeDialog = payload;
  },
  [actionTypes.REFRESH_DIALOG](state) {
    state.activeDialog = { ...state.activeDialog };
  },
  [actionTypes.CLOSE_DIALOG](state) {
    state.activeDialog = null;
  },
  [actionTypes.ADD_TO_MENU_BADGE](state, payload) {
    if (!payload.resource) {
      return;
    }

    state.menuBadges = {
      ...state.menuBadges,
      [payload.resource]: [
        ...state.menuBadges[payload.resource],
        ...payload.keys,
      ],
    };
  },
  [actionTypes.CLEAR_MENU_BADGE](state, payload) {
    if (!payload.resource) {
      return;
    }

    state.menuBadges = {
      ...state.menuBadges,
      [payload.resource]: [],
    };
  },
  [actionTypes.SET_OFFLINE](state, payload) {
    state.offline = payload;
  },
  [actionTypes.SET_IS_MOBILE](state, payload) {
    state.isMobile = payload;
    if (payload) {
      StatusBar.setStyle({
        color: "#9eeacb",
      });
    }
  },
  [actionTypes.SET_REACTIVITY_PARAMS](state, payload) {
    const since = moment().format('yyyy-MM-DDTHH:mm:ss');
    if (payload instanceof Array) {
      state.reactivityParams = [
        ...state.reactivityParams.filter((f) => !payload.find(p =>
          f.resource === p.resource ||
          f.scope === p.scope ||
          f.key === p.key)),
        ...payload.map(m => ({...m, since})),
      ];
    } else if(payload) {
      state.reactivityParams = [
        ...state.reactivityParams.filter((f) => 
          f.resource !== payload.resource ||
          f.scope !== payload.scope ||
          f.key !== payload.key),
        {...payload, since},
      ];
    }
  },
  [actionTypes.CLEAR_REACTIVITY_PARAMS](state, payload) {
    state.reactivityParams = [];
  },
  [actionTypes.REFRESH_REACTIVITY_PARAMS](state, payload) {
    state.reactivityParams = state.reactivityParams.map((m) => ({...m, since: moment().format('yyyy-MM-DDTHH:mm:ss')}));
  },
  [actionTypes.SET_VIEW_DATA](state, payload) {
    state.viewData = {...state.viewData, ...payload};
  },
  [actionTypes.CLEAR_VIEW_DATA](state, payload) {
    state.viewData = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

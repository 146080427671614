import { App } from '@capacitor/app';

// Variable to store the timestamp of the last back button press
let lastBackPressedAt = 0;

export default function setupBackButtonListener() {
  App.addListener('backButton', (event) => {
    const now = Date.now();
    const timeSinceLastPress = now - lastBackPressedAt;
    const doublePressInterval = 1000; // Adjust this value as needed (in milliseconds)

    if (timeSinceLastPress < doublePressInterval) {
      // If the back button is pressed twice within the interval, exit the app
      App.exitApp();
    } else {
      // If not, prevent the default behavior (going back)
      event.preventDefault();
      // Replace with your home screen route
      window.location.href = `#/`;
    }

    lastBackPressedAt = now;
  });
}

import Vue from "vue";
import { apiService } from "@/services";
import { apiActions } from "@/services";
import util from "@/common/helpers/util";

const getAction = (name) => {
  const pluralName = name.endsWith("y")
    ? name.substr(0, name.length - 1) + "ies"
    : name.endsWith("s")
      ? name + "es"
      : name + "s";

  const actionTypes = {
    SAVE: `save${name}`,
    UPDATE: `update${name}`,
    RESET: `reset${name}`,
    DELETE: `delete${name}`,
    DELETE_BY_PARAMS: `delete${name}ByParams`,
    LOAD: `load${pluralName}`,
    LOAD_BY_PARENT_ID: `load${pluralName}ByParentId`,
    LOAD_BY_PARENT_UUID: `load${pluralName}ByParentUuid`,
    LOAD_BY_ID: `load${name}`,
    LOAD_BY_UUID: `load${name}ByUuid`,
    LOAD_BY_COMPLETED: `load${name}Completed`,
    LOAD_BY_PARAMS: `load${pluralName}ByParams`,
    LOAD_BY_PARAMS_COMPLETED: `load${pluralName}ByParamsCompleted`,
    RELOAD_BY_PARAMS: `reload${pluralName}ByParams`,
    RELOAD_BY_PARAMS_COMPLETED: `reload${pluralName}ByParamsCompleted`,
    REMOVE_FROM_STATE: `remove${pluralName}FromState`,
    LOAD_BY_PROP: `load${pluralName}ByProp`,
    LOAD_COMPLETED: `load${pluralName}Completed`,
    SET_PROPS: `set${name}Props`,
    SET_SELECTED: `setSelected${name}`,
    CLEAR_SELECTED: `clearSelected${name}`,

    GET_SELECTED: `getSelected${name}`,
    GET_ITEM_BY_ID: `get${name}ById`,
    GET_ITEM_BY_UUID: `get${name}ByUuid`,
    GET_ITEM_BY_PROP: `get${name}ByProp`,
    GET_ALL: `get${pluralName}`,
    GET_BY_PARENT_ID: `get${pluralName}ByParentId`,
    GET_BY_PROP: `get${pluralName}ByProp`,
  };

  return actionTypes;
};

const state = {
  items: [],
  selected: null,
  loading: false,
  loadedSuccessfully: false,
};

const getters = (name, parentId) => {
  return {
    [getAction(name).GET_SELECTED]: (state) => {
      return state.selected;
    },
    [getAction(name).GET_ALL]: (state) => {
      return state.items;
    },
    [getAction(name).GET_ITEM_BY_ID]: (state) => (idOrIds) => {
      if (idOrIds instanceof Array) {
        return state.items.filter((item) => idOrIds.includes(item.id));
      } else {
        return state.items.find((item) => item.id === idOrIds);
      }
    },
    [getAction(name).GET_ITEM_BY_UUID]: (state) => (value) => {
      if (value instanceof Array) {
        return state.items.filter((item) => value.includes(item.uuid));
      } else {
        return state.items.find((item) => item.uuid === value);
      }
    },
    [getAction(name).GET_ITEM_BY_PROP]: (state) => (prop, value) => {
      return state.items.find((item) => item[prop] === value);
    },
    [getAction(name).GET_BY_PARENT_ID]: (state) => (idOrIds) => {
      if (idOrIds instanceof Array) {
        return state.items.filter((item) => idOrIds.includes(item[parentId]));
      } else {
        return state.items.filter((item) => item[parentId] === idOrIds);
      }
    },
    [getAction(name).GET_BY_PROP]: (state) => (prop, value) => {
      if (value && value instanceof Array) {
        return state.items.filter((item) => value.includes(item[prop]));
      } else {
        return state.items.filter((item) => item[prop] === value);
      }
    },
  };
};

const actions = (name, apiController) => {
  return {
    [getAction(name).SET_PROPS]({ commit }, params) {
      commit(getAction(name).SET_PROPS, params);
    },
    async [getAction(name).LOAD]({ commit }) {
      return apiActions.load(
        apiController,
        null,
        commit,
        getAction(name).LOAD);
    },
    async [getAction(name).LOAD_BY_PARENT_ID]({ commit }, idOrIds) {
      commit(getAction(name).LOAD);
      return new Promise((resolve, reject) => {
        apiService.get(
          `${apiController}/${idOrIds instanceof Array ? "parents" : "parent/" + idOrIds
          }`,
          idOrIds instanceof Array ? idOrIds : null
        )
          .then(({ data, status }) => {
            commit(getAction(name).LOAD_COMPLETED, {
              success: status === 200,
              data,
            });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async [getAction(name).LOAD_BY_PARENT_UUID]({ commit }, uuid) {
      commit(getAction(name).LOAD);
      return new Promise((resolve, reject) => {
        apiService.get(`${apiController}/parent/uuid/${uuid}`)
          .then(({ data, status }) => {
            commit(getAction(name).LOAD_COMPLETED, {
              success: status === 200,
              data,
            });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async [getAction(name).LOAD_BY_ID]({ commit }, id) {
      if (!id) {
        console.log("Id parameter is missing")
        return;
      }

      commit(getAction(name).LOAD);
      return new Promise((resolve, reject) => {
        apiService.get(`${apiController}/${id}`)
          .then(({ data, status }) => {
            commit(getAction(name).LOAD_BY_COMPLETED, {
              success: status === 200,
              data,
            });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async [getAction(name).LOAD_BY_UUID]({ commit }, uuid) {
      if (!uuid) {
        console.log("Id parameter is missing")
        return;
      }

      commit(getAction(name).LOAD);
      return new Promise((resolve, reject) => {
        apiService.get(`${apiController}/uuid/${uuid}`)
          .then(({ data, status }) => {
            commit(getAction(name).LOAD_BY_COMPLETED, {
              success: status === 200,
              data,
            });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async [getAction(name).LOAD_BY_PARAMS]({ commit }, params) {
      return apiActions.load(
        `${apiController}/list`,
        params,
        commit,
        getAction(name).LOAD);
    },
    async [getAction(name).RELOAD_BY_PARAMS]({ commit }, params) {
      return apiActions.load(
        `${apiController}/list`,
        params,
        commit,
        getAction(name).RELOAD_BY_PARAMS);
    },
    async [getAction(name).REMOVE_FROM_STATE]({ commit }, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          console.log("Params argument is missing")
          reject("Params argument is missing")
        }

        try {
          commit(getAction(name).REMOVE_FROM_STATE, params);
          resolve();
        } catch(err) {
          reject();
        }
      });
    },
    async [getAction(name).LOAD_BY_PROP]({ commit }, params) {
      commit(getAction(name).LOAD);
      return new Promise((resolve, reject) => {
        apiService.get(`${apiController}/${params.prop}/${params.value}`)
          .then(({ data, status }) => {
            commit(getAction(name).LOAD_COMPLETED, {
              success: status === 200,
              data,
            });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async [getAction(name).SAVE]({ commit }, params) {
      if (!params) {
        return;
      }

      const { editMode, item, files } = params;

      if (editMode) {
        let patchAction = null;
        if(files) {
          patchAction = apiService.patchAndUpload
        } else {
          patchAction = apiService.patch;
        }

        return patchAction(apiController, item, files)
          .then(({ data }) => {
            commit(getAction(name).SAVE, { editMode, payload: data });
            Vue.popup("messages.updateDone", "success");
          })
          .catch((error) => {
            Vue.popup("messages.operationError", "error");
            throw new Error(error);
          });
      } else {
          let postAction = null;
          if(files) {
            postAction = apiService.postAndUpload
          } else {
            postAction = apiService.post;
          }
          return postAction(apiController, item, files)
          .then(({ data }) => {
            commit(getAction(name).SAVE, { editMode, payload: data });
            Vue.popup("messages.done", "success");
          })
          .catch((error) => {
            Vue.popup("messages.operationError", "error");
            throw new Error(error);
          });
      }
    },
    async [getAction(name).UPDATE]({ commit }, params) {
      if (!params) {
        return;
      }

      const { url, item } = params;

      return apiActions.update(
        `${apiController}${url}`,
        item,
        commit,
        getAction(name).UPDATE,
      );
    },
    [getAction(name).DELETE]({ commit }, id) {
      return apiActions.delete(
        apiController,
        id,
        commit,
        getAction(name).DELETE,
        {
          onSuccess: () => {
            commit(getAction(name).CLEAR_SELECTED);
          },
        }
      );
    },
    [getAction(name).DELETE_BY_PARAMS]({ commit }, params) {
      return apiActions.delete(
        apiController,
        params,
        commit,
        getAction(name).DELETE_BY_PARAMS,
        {
          onSuccess: () => {
            commit(getAction(name).CLEAR_SELECTED);
          },
        }
      );
    },
    [getAction(name).SET_SELECTED]({ commit }, item) {
      commit(getAction(name).SET_SELECTED, item);
    },
    [getAction(name).RESET]({ commit }, item) {
      commit(getAction(name).RESET, item);
    },
    [getAction(name).CLEAR_SELECTED]({ commit }) {
      commit(getAction(name).CLEAR_SELECTED);
    },
  };
};

const mutations = (name) => {
  return {
    [getAction(name).SET_PROPS](state, payload) {
      Vue.set(state, payload.stateCollection || "items", [
        ...state.items.filter((f) => f.id !== payload.id),
        { ...state.items.find((f) => f.id === payload.id), ...payload.props }
      ]);
    },
    [getAction(name).LOAD](state) {
      Vue.set(state, "loadedSuccessfully", false);
      Vue.set(state, "loading", true);
    },
    [getAction(name).LOAD_COMPLETED](state, payload) {
      Vue.set(state, "loadedSuccessfully", payload.success);
      Vue.set(state, "loading", false);
      Vue.set(state, "items", payload.data instanceof Array ?
        [...payload.data] :
        [...state.items.filter((f) => f.id !== payload.data.id), payload.data]);
    },
    [getAction(name).LOAD_BY_COMPLETED](state, payload) {
      state.loading = false;
      Vue.set(state, "items", [...state.items.filter(f => f.id !== payload.data.id), payload.data]);
    },
    [getAction(name).LOAD_BY_PARAMS_COMPLETED](state, payload) {
      state.loading = false;
      Vue.set(state, "items", [...payload.data]);
    },
    [getAction(name).RELOAD_BY_PARAMS_COMPLETED](state, payload) {
      state.loading = false;
      Vue.set(state, "items",
        [...state.items.filter((f) => !payload.data.some((s) => s.id === f.id)), ...payload.data]);
    },
    [getAction(name).SAVE](state, params) {
      const { editMode, payload } = params;
      if (!payload) {
        return;
      }

      if (editMode) {
        Vue.set(
          state,
          "items",
          state.items.map((item) => {
            if (item.id === payload.id) {
              return { ...item, ...payload };
            }
            return item;
          })
        );
      } else {
        Vue.set(state, "items", [...state.items, payload]);
      }
    },
    [getAction(name).UPDATE](state, params) {
      const { payload, success } = params;
      if (success && payload) {
        Vue.set(
          state,
          "items",
          state.items.map((item) => {
            if (item.id === payload.id) {
              return { ...item, ...payload };
            }
            return item;
          })
        );
      }
    },
    [getAction(name).DELETE](state, params) {
      const { payload, success } = params;
      if (success) {
        Vue.set(
          state,
          "items",
          state.items.filter((item) => item.id !== payload)
        );
      }
    },
    [getAction(name).REMOVE_FROM_STATE](state, payload) {
      if (payload) {
        Vue.set(
          state,
          "items",
          params instanceof Array ?
            state.items.filter((item) => !payload.includes(item.id)) :
            state.items.filter((item) => item.id !== payload)
        );
      }
    },
    [getAction(name).DELETE_BY_PARAMS](state, params) {
      const { payload, success } = params;
      if (success) {
        Vue.set(
          state,
          "items",
          state.items.filter((item) => !util.partiallyCompare(item, payload))
        );
      }
    },
    [getAction(name).RESET](state, payload) {
      const items = state.items.map((item) => {
        if (payload && item.id === payload.id) {
          return { ...item, ...payload };
        }
        return item;
      });

      Vue.set(state, "items", items);
    },
    [getAction(name).SET_SELECTED](state, payload) {
      Vue.set(state, "selected", payload);
    },
    [getAction(name).CLEAR_SELECTED](state) {
      Vue.set(state, "selected", null);
    },
  };
};

export default (name, apiController, parentProp) => {
  return {
    namespaced: true,
    state: { ...state },
    actions: { ...actions(name, apiController) },
    mutations: { ...mutations(name) },
    getters: { ...getters(name, parentProp) },
  };
};

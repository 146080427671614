import moment from "moment";

const cases = [
  {
    status: "WAITING",
    color: "blue lighten-3",
    text: "white"
  },
  {
    status: "PREPARING",
    color: "yellow",
    text: "black"
  },
  {
    status: "LOADING",
    color: "red lighten-1",
    text: "white"
  },
  {
    status: "DISPATCHED",
    color: "deep-purple darken-2",
    text: "white"
  },
  {
    status: "DELIVERY",
    color: "blue darken-2",
    text: "white"
  },
  {
    status: "NEAR",
    color: "green accent-3",
    text: "white"
  },
  {
    status: "DELIVERED",
    color: "green lighten-2",
    text: "black"
  },
  {
    status: "RETURNED",
    color: "deep-blue darken-4",
    text: "white"
  },
  {
    status: "CANCELLED",
    color: "red darken-1",
    text: "white"
  },
  {
    status: "NOT ACCEPTED",
    color: "blue-grey darken-3",
    text: "white"
  }
];

export default {
  methods: {
    getStatusTypeColor(status) {
      return cases.find(f => f.status === status)?.color || "white";
    },
    isDarkStatusTheme(status) {
      return ["WAITING", "PREPARING", "LOADING"].indexOf(status) === -1;
    },
    formatTime(time, dayName) {
      return moment(time).format(
        dayName ? "ddd | DD/MM/YYYY hh:mm A" : "DD/MM/YYYY hh:mm A"
      );
    },
    isActiveOrder(s) {
      const activeStatus = [
        "WAITING",
        "PREPARING",
        "LOADING",
        "DISPATCHED",
        "DELIVERY",
        "NEAR",
      ];
      return activeStatus.includes(s);
    },
    getAddressText(order) {
      if (!order) {
        return;
      }

      const a = order.address;
      if (!a) {
        return;
      }

      return `${a.buildingNumber} ${a.street}${a.area ? ", " + a.area : ""} | Coordinates: (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
    getAddress(order) {
      if (
        !order ||
        !order.addressId ||
        !this.notEmptyArray(order.customerAddresses)
      ) {
        return "";
      }
      const { addressId, customerAddresses } = order;
      return customerAddresses.find((f) => f.id === addressId);
    },
    getAddressString(order) {
      const a = this.getAddress(order);
      return `${a.area} | Street: ${a.street} | Building/House/Apr. # ${a.buildingNumber}`;
    },
  },
};

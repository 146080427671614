export const LOGIN = "login";
export const REQUEST_LOGIN_PIN = "requestLoginPin";
export const PERMITTED = "permitted";
export const PIN = "pin";
export const CONFIRM_PIN = "confirmPin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const COMPLETE_REGISTRATION = "completeRegistration";
export const SAVE_PROFILE = "saveProfile";
export const PASSWORD_RESET = "passwordReset";
export const SEND_PIN_CODE = "sendPinCode";
export const RESET_PASSWORD = "resetPassword";
export const CHANGE_PHONE = "changePhone";
export const CHANGE_EMAIL = "changeEmail";
export const SELECT_USER_REGION = "selectUserRegion";
export const HANDSHAKE_SERVER = "handshakeServer";

export const SET_AUTH = "setAuth";
export const UPDATE_USER_STATE = "updateUserState";
export const CHECK_AUTH = "checkAuth";
export const HEARTBEAT = "heartbeat";
export const PURGE_AUTH = "purgeAuth";

export const GET_CURRENT_USER = "getCurrentUser";
export const IS_AUTHENTICATED = "isAuthenticated";

export const CODE_CONFIRM_EMAIL = "cce-";
export const CODE_CONFIRM_PHONE = "ccp-";
export const CODE_RESET_PASSWORD = "crp-";

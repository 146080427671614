import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import ar from "./ar";

Vue.use(VueI18n);

const messages = {
  en: {
    ...en,
    // add more resources
  },
  ar: {
    ...ar,
    // add more resources
  },
};

export function getLanguage() {
  const chooseLanguage = localStorage.getItem("language");
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "en";
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  fallbackLocale: "en",
  // set locale messages
  messages,
});

export default i18n;

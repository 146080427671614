import Vue from "vue";
import { apiService } from "@/services";
import * as actionTypes from "../actions/type.cart";
import { GET_OPEN_STORE_ID, GET_STORE_BY_ID  } from "../actions/type.stores";
import { SHOW_DIALOG, CLOSE_DIALOG  } from "../actions/type.ui";
import _ from "lodash";
import util from "@/common/helpers/util";

const state = {
  uuid: util.createUUID(),
  items: [],
  coupon: null,
  inProgressLock: false,
  checkoutFeedback: [],
  preChecking: false,
};

// getters
const getters = {

  [actionTypes.GET_CART_CURRENCY](state, getters) {
    const store = getters[actionTypes.GET_CART_STORE];
    if (!store || !store.region) {
      return "YE";
    }
    const { currency } = store.region;
    return currency;
  },
  [actionTypes.GET_CART_STORE](state, getters, rootState, rootGetters) {
    const cartStoreId = getters[actionTypes.GET_CART_STORE_ID];
    const store = rootGetters["stores/" + GET_STORE_BY_ID](cartStoreId);
    return store;
  },
  [actionTypes.GET_CART_STORE_ID](state) {
    const filled = state.items && state.items.length > 0;
    return filled ? state.items[0].storeId : null;
  },
  [actionTypes.IS_IN_PROGRESS](state) {
    return state.inProgressLock;
  },
  [actionTypes.IS_CART_LOCKED](state, getters, rootState, rootGetters) {
    const filled = state.items && state.items.length > 0;
    const cartStoreId = getters[actionTypes.GET_CART_STORE_ID];
    return filled && rootGetters["stores/" + GET_OPEN_STORE_ID] !== cartStoreId;
  },
  [actionTypes.GET_ACTIVE_CART_STORE_ID](state, getters, rootState, rootGetters) {
    const filled = state.items && state.items.length > 0;
    const cartStoreId = getters[actionTypes.GET_CART_STORE_ID];
    return filled ? cartStoreId : null;
  },
  // address out of service
  [actionTypes.IS_AOOS_LOCKED](state, getters, rootState) {
    const cartStoreId = getters[actionTypes.GET_CART_STORE_ID];
    const { storeIds } = rootState.auth.user;
    return !storeIds || !storeIds.includes(cartStoreId);
  },
  [actionTypes.GET_CART_ITEMS](state) {
    return state.items;
  },
  [actionTypes.GET_CART_ITEMS_QUANTITY](state) {
    return state.items.length;
  },
  [actionTypes.GET_CART_ITEM]: (state) => (productId) => {
    return state.items.find((f) => f.id === productId);
  },
  [actionTypes.GET_CART_ITEM_QUANTITY]: (state) => (productId) => {
    const item = state.items.find((f) => f.id === productId);
    return item ? item.quantity : 0;
  },
  [actionTypes.GET_CART_TOTAL_PRICE](state) {
    return _.sumBy(state.items, function(o) {
      return o.quantity * o.unitPrice;
    });
  },
  [actionTypes.GET_CART_GRAND_TOTAL]: (state, getters) => {
    const store = getters[actionTypes.GET_CART_STORE];
    const total = getters.getCartTotalPrice;
    if (
      total > 0 &&
      store &&
      store.freeDeliveryPrice > total
    ) {
      return total + store.deliveryFees;
    } else {
      return total;
    }
  },
  [actionTypes.IS_FREE_DELIVERY](state, getters) {
    const store = getters[actionTypes.GET_CART_STORE];
    const total = getters.getCartTotalPrice;
    return store?.freeDeliveryPrice >= 0 && total >= store.freeDeliveryPrice;
  },
};

// actions
const actions = {
  [actionTypes.ADD_TO_CART]({ commit }, item) {
    commit(actionTypes.ADD_TO_CART, item);
  },
  [actionTypes.REMOVE_FROM_CART]({ commit }, productId) {
    commit(actionTypes.REMOVE_FROM_CART, productId);
  },
  [actionTypes.DELETE_FROM_CART]({ commit }, productId) {
    commit(actionTypes.DELETE_FROM_CART, productId);
  },
  [actionTypes.CLEAR_CART]({ commit, dispatch }) {
    const dialog = {
      translate: true,
      title: "general.clear",
      text: "cart.messages.clearAction",
      actions: [
        {
          text: "Yes",
          click: () => {
            commit(actionTypes.CLEAR_CART);
          },
          color: "primary",
        },
        {
          text: "No",
          click: () => {
            dispatch(`ui/${CLOSE_DIALOG}`, null, { root: true });
            if (to.name !== "login") {
              router.go(-1);
            }
          },
          color: "primary",
        },
      ],
    };
    dispatch(`ui/${SHOW_DIALOG}`, dialog, { root: true });
  },
  async [actionTypes.CHECKOUT_CART]({ commit, state, rootState, getters }, storeId) {
    commit(actionTypes.CHECKOUT_CART);
    const store = getters[actionTypes.GET_CART_STORE];
    if(store.id !== storeId) {
      // TODO: show more explainatory message
      Vue.popup("messages.error", "error");
      return;
    }

    return new Promise((resolve, reject) => {
      const items = [...state.items];
      const uuid = state.uuid;
      const couponCode = state.coupon;
      const { activeAddress:adr } = rootState.auth.user;

      if (!adr) {
        Vue.popup("messages.notDefaultAddress", "error");
        return;
      }

      const order = {
        uuid,
        items,
        couponCode,
        storeId,
        addressId: adr.id,
      };

      apiService.post("orders/checkout", order)
        .then(({ data, status }) => {
          console.log("checkout...")
          commit(actionTypes.CHECKOUT_CART_COMPLETED, {
            success: status === 200,
            data,
          });

          if (status === 200) {
            commit(actionTypes.CLEAR_CART);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data) {
            Vue.popup(response.data);
            // TODO: call OUT_OF_STOCK action,
            // make product no longer available for selection
            // throughout all clients (from server directly)
          }

          reject(response);
        });
    });
  },
  async [actionTypes.PRECHECK_CART]({ commit, state, rootState, getters }) {
    commit(actionTypes.PRECHECK_CART);
    return new Promise((resolve, reject) => {
      const store = getters[actionTypes.GET_CART_STORE];
      const items = [...state.items];
      const uuid = state.uuid;
      const { activeAddress:adr } = rootState.auth.user;

      if (!adr) {
        Vue.popup("messages.notDefaultAddress", "error");
        return;
      }

      const order = {
        uuid,
        items,
        storeId: store.id,
        addressId: adr.id,
      };

      apiService.post("orders/precheck", order)
        .then(({data}) => {
          commit(actionTypes.PRECHECK_CART_COMPLETED, data);
          resolve(data);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
  async [actionTypes.CHECK_STOCK]({}, id) {
    return new Promise((resolve, reject) => {
      apiService.query("products/stock/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

// mutations
const mutations = {
  [actionTypes.ADD_TO_CART](state, payload) {
    if (state.items.find((f) => f.id === payload.id)) {
      state.items = state.items.map((item) => {
        if (item.id === payload.id && item.quantity < payload.stock) {
          return { ...item, quantity: item.quantity + 1 };
        }

        return item;
      });
    } else {
      state.items = [...state.items, { ...payload, quantity: 1 }];
    }
  },
  [actionTypes.REMOVE_FROM_CART](state, payload) {
    state.items = state.items
      .map((item) => {
        if (item.id === payload) {
          if (item.quantity > 1) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return null;
          }
        }
        return item;
      })
      .filter((f) => f !== null);
  },
  [actionTypes.DELETE_FROM_CART](state, payload) {
    state.items = state.items.filter((item) => item.id !== payload);
  },
  [actionTypes.CLEAR_CART](state) {
    state.items = [];
    state.inProgressLock = false;
    state.preChecking = false;
    state.checkoutFeedback = [];
  },
  [actionTypes.CHECKOUT_CART](state) {
    state.uuid = util.createUUID();
    state.inProgressLock = true;
  },
  [actionTypes.CHECKOUT_CART_COMPLETED](state) {
    state.items = [];
    state.inProgressLock = false;
    state.preChecking = false;
    state.checkoutFeedback = [];
  },
  [actionTypes.PRECHECK_CART](state) {
    state.preChecking = true;
  },
  [actionTypes.PRECHECK_CART_COMPLETED](state, payload) {
    state.preChecking = false;
    state.checkoutFeedback = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

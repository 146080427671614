import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Touch } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Touch,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#003459",
        secondary: "#00ff9f",
        accent: "#607d8b",
        error: "#ff4721",
        warning: "#ffdf2d",
        info: "#40bcd4",
        success: "#00c688",
      },
    },
  },
});

// actions & mutations
export const LOAD_ACTIVE_USERS = "loadActiveUsers";
export const LOAD_ACTIVE_USERS_COMPLETED = "loadActiveUsersCompleted";
export const LOAD_DISPATCHED_DRIVERS = "loadDispatchedDrivers";
export const LOAD_DISPATCHED_DRIVERS_COMPLETED = "loadDispatchedDriversCompleted";
export const LOAD_STORE_USERS_BY_TYPE = "loadStoreUsersByType";
export const LOAD_STORE_USERS_BY_TYPE_COMPLETED = "loadStoreUsersByTypeCompleted";
export const SET_DRIVERS_ON_DUTY = "setDriversOnDuty";
export const SET_DRIVER_STATUS = "setDriverStatus"
export const SAVE_USER_ROLES = "saveUserRoles";
export const SAVE_USER_ROLES_COMPLETED = "saveUserRolesCompleted";
export const UPDATE_ASSIGNED_STORES = "updateAssignedStores";
export const UPDATE_ASSIGNED_STORES_COMPLETED = "updateAssignedStoresCompleted";
export const PUBLISH_LOCATION = "publishLocation";
export const UNPUBLISH_LOCATION = "unpublishLocation";

export const LOAD_ONLINE_USERS = "loadOnlineUsers";
export const LOAD_ONLINE_USERS_COMPLETED = "loadOnlineUsersCompleted";
export const GET_ONLINE_USERS = "getOnlineUsers";
export const FORCE_LOGOUT = "forceLogout";
export const START_TRACK = "startTrack";
export const BEEP_ALERT = "beepAlert";

// getters
export const GET_DISPATCHED_DRIVERS = "getActiveDrivers";
export const GET_DRIVERS_ON_DUTY = "getDriversOnDuty";
export const GET_LOCATION = "getLocation";

// types 
export const USER_TYPE_SUPER_ADMIN = "SUPER-ADMIN";
export const USER_TYPE_ADMIN = "ADMIN";
export const USER_TYPE_ORDER_HANDLER = "ORDER-HANDLER";
export const USER_TYPE_DRIVER = "DRIVER";
export const USER_TYPE_CUSTOMER = "CUSTOMER";
export const USER_TYPE_MERCHANT = "MERCHANT";


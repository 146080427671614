import moment from "moment";

const cases = [
  {
    status: "WAITING",
    color: "blue lighten-3",
    text: "white"
  },
  {
    status: "PREPARING",
    color: "yellow",
    text: "black"
  },
  {
    status: "LOADING",
    color: "red lighten-1",
    text: "white"
  },
  {
    status: "DISPATCHED",
    color: "deep-purple darken-2",
    text: "white"
  },
  {
    status: "DELIVERY",
    color: "blue darken-2",
    text: "white"
  },
  {
    status: "NEAR",
    color: "lime accent-3",
    text: "black"
  },
  {
    status: "DELIVERED",
    color: "green accent-2",
    text: "black"
  },
  {
    status: "RETURNING",
    color: "cyan darken-4",
    text: "white"
  },
  {
    status: "RETURNED",
    color: "cyan darken-4",
    text: "white"
  },
  {
    status: "CANCELLED",
    color: "grey",
    text: "white"
  },
  {
    status: "NOT ACCEPTED",
    color: "blue-grey darken-3",
    text: "white"
  }
];

const display = {
  getStatusColor(status) {
    return cases.find(f => f.status === status)?.color || "white";
  },
  getStatusTextColor(status) {
    return cases.find(f => f.status === status)?.text || "black";
  },
  isDarkStatusTheme(status) {
    return ["WAITING", "PREPARING", "LOADING"].indexOf(status) === -1;
  },
  formatTime(time, dayName) {
    return moment(time).format(
      dayName ? "ddd | DD/MM/YYYY hh:mm A" : "DD/MM/YYYY hh:mm A"
    );
  },
  getAddressText(order) {
    if (!order) {
      return;
    }

    const a = order.address;
    if (!a) {
      return;
    }

    return `${a.buildingNumber} ${a.street}${a.area ? ", " + a.area : ""} | Coordinates: (
      ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
      ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
  },
  isActiveOrder(s) {
    const activeStatus = [
      "WAITING",
      "PREPARING",
      "LOADING",
      "DISPATCHED",
      "DELIVERY",
      "NEAR",
    ];
    return activeStatus.includes(s);
  },
};

export default {
  install(Vue) {
    Vue.prototype.$display = display;
    // Vue.mixin({ methods: {
    //   display,
    // },});
  },
};
import Vue from "vue";
import App from "./App";
import router from "./router";
import vuetify from "./plugins/vuetify";
import fullscreen from "vue-fullscreen";
import i18n from "./locales";
import { apiService } from "./services";
import store from "./store";
import util from "./common/helpers/util";
import { HANDSHAKE_SERVER, HEARTBEAT } from "./store/actions/type.auth";
import { SET_LANGUAGE, SET_THEME } from "./store/actions/type.ui";
import {
  Display,
  Authorization,
  Geo,
  Upload,
  // WebSocket,
  EventsBus,
  Messaging,
  AppRouter,
  Clipboard,
  Notification,
  FormValidation,
  setupBackButtonListener,
} from "./plugins";
import VueOffline from "vue-offline";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@capacitor/core";
import "./registerServiceWorker";
// import { authorizationService } from "@/services";

Vue.config.productionTip = false;

// Ensure we checked auth before each page load.
Vue.use(Display);
Vue.use(Authorization);
Vue.use(Geo, { store });
Vue.use(Clipboard);
Vue.use(Upload);
// Vue.use(WebSocket);
Vue.use(EventsBus);
Vue.use(Messaging, { store });
Vue.use(AppRouter, { store, router });
Vue.use(VueOffline, { mixin: false });
Vue.use(fullscreen);
Vue.use(Notification, { store });
Vue.use(FormValidation);
Vue.socketConnect();
Vue.permitGeo();


setupBackButtonListener();

// const { Device } = Plugins;
// const init = async () => {
//   const device = await Device.getInfo();
//   const debug = process.env.NODE_ENV !== "production";
//   let url = process.env.VUE_APP_API_URL;
//   if (debug && device.platform !== "web") {
//     url = process.env.VUE_APP_API_MOBILE_BASE_URL;
//   }
// }
// init();
// let url = process.env.VUE_APP_API_URL;
apiService.init(store);

//initiate first handshake with server
store.dispatch(`auth/${HANDSHAKE_SERVER}`, null, { root: true });

// send online-status update
setInterval(() => {
  store.dispatch(`auth/${HEARTBEAT}`, null, { root: true });

  // check socket connection
  // if(!store.state.socket.connected) {
  //   console.log("socket disconnected, attempting reconnect...")
  //   Vue.socketConnect();
  // }

}, 20000);

Vue.mixin({
  methods: {
    ...util,
    getServerImage(path) {
      return `${this.getApiBaseUrl}/files/image/?path=${path}`;
    },
    changeLanguage(lang) {
      store.dispatch(`ui/${SET_LANGUAGE}`, lang);
      this.$i18n.locale = lang;
      this.$vuetify.rtl = lang === "ar";
    },
    getBorderColor() {
      return this.$vuetify.theme.themes.light.secondary + "75";
    },
    getThemeColor(name) {
      return this.$vuetify.theme.themes.light[name];
    },
    initTheme() {
      const theme = this.getTheme;
      if (theme) {
        this.$vuetify.theme.themes.name = theme.name;
        for (var i in this.$vuetify.theme.themes.light) {
          this.$vuetify.theme.themes.light[i] = theme.light[i];
        }
      }
    },
    getViewHeightStyle(mdHeight = 56) {
      return `height: calc(100vh - ${mdHeight}px); max-height: calc(100vh - ${mdHeight}px);`;
      // if (this.$vuetify.breakpoint.mdAndDown) {
      // } else {
      //   return `height: calc(100vh - ${mdHeight + gap}px); max-height: calc(100vh - ${mdHeight + gap}px);`;
      // }
    },
    getAvatar(uuid) {
      return `${process.env.VUE_APP_API_URL}/files/avatar/${uuid}`;
    },
    getRouteName(name) {
      return name ? name
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase() : "";
    },
  },
  computed: {
    getTheme() {
      const themeTypes = [
        { type: ["CUSTOMER", "*"], name: "default" },
        { type: ["DRIVER"], name: "driver" },
        { type: ["MERCHANT"], name: "merchant" },
        { type: ["MANAGER", "ORDER-HANDLER"], name: "admin" },
        { type: ["ADMIN"], name: "admin" },
      ];

      const accountType = process.env.VUE_APP_CLIENT_TYPE || window.localStorage.getItem("type") || "*";
      const theme = themeTypes.find((f) => f.type.includes(accountType));
      const themeName = theme ? theme.name : themeTypes[0].name;

      store.dispatch(`ui/${SET_THEME}`, themeName);
      const themes = store.state.ui.themes;
      return themes.find((f) => f.name === themeName);
    },
    getLocale() {
      return {
        lang: this.$i18n.locale,
        rtl: this.$vuetify.rtl === "ar",
      };
    },
    getApiBaseUrl() {
      return process.env.VUE_APP_API_URL;
    },
    isMd() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    getVersion() {
      return process.env.VUE_APP_VERSION;
    },
    getOptions() {
      return {
        products: {
          showRating: false,
          showDescription: true,
          showTags: false,
        },
      };
    },
    isNative() {
      return platform.isNative;
    },
    isAndroid() {
      return platform.is('android');
    },
    isAndroid() {
      return platform.is('ios');
    },
    generateUuid() {
      return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }
  },
});

const app = new Vue({
  store,
  router,
  beforeCreate: async function () {
    await this.$store.dispatch('roles/loadRoles', null, { root: true });
  },
  vuetify,
  Authorization,
  Upload,
  i18n,
  L,
  render: (h) => h(App),
}).$mount("#app");

app.changeLanguage(store.state.ui.language);
app.initTheme();

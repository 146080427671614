import { apiService } from "@/services";
import * as actionTypes from "../actions/type.chat";

const API_PATH = "chat";

const state = {
  rooms: [],
  selected: null,
  contacts: [],
};

const getters = {
  [actionTypes.GET_CHAT_ROOMS](state) {
    return state.rooms;
  },
  [actionTypes.GET_CHAT_ROOM]:(state) => (room) => {
    return state.rooms.find((f) => f.uuid === room);
  },
  [actionTypes.GET_SELECTED_CHAT_ROOM](state) {
    return state.selected ? state.rooms.find((f) => f.uuid === state.selected) : null;
  },
  [actionTypes.GET_CHAT_MESSAGES]:(state) => (room) => {
    const r = state.rooms.find((f) => f.uuid === room);
    return r ? r.messages : [];
  },
  [actionTypes.GET_CONTACTS](state) {
    return state.contacts;
  },
};

const actions = {
  [actionTypes.LOAD_CHAT_ROOM]({ commit }, room) {
    return new Promise((resolve, reject) => {
      apiService.get(`${API_PATH}/${room}`)
        .then(({ data }) => {
          commit(actionTypes.LOAD_CHAT_ROOM, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [actionTypes.LOAD_CHAT_MESSAGES]({ commit }, room) {
    return new Promise((resolve, reject) => {
      apiService.get(`${API_PATH}/${room}`)
        .then(({ data }) => {
          commit(actionTypes.LOAD_CHAT_MESSAGES, { room: data });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.START_CHAT_ROOM]({ commit }, message) {
    if(!message) {
      return;
    }
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/start`, message)
        .then(({ data }) => {
          commit(actionTypes.START_CHAT_ROOM, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.SEND_CHAT_MESSAGE]({ commit }, message) {
    if(!message) {
      return;
    }

    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/send`, message)
        .then(({ data }) => {
          const payload = { room, message: data };
          commit(actionTypes.SEND_CHAT_MESSAGE, payload);
          resolve(payload);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.INVITE_CHAT_PARTICIPANTS]({ commit }, { room, participants }) {
    if(!message || !room) {
      return;
    }
    const params = participants.map((m) => ({uuid: m, room: { uuid: room }}));

    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/invite/${room}`, params)
        .then(({ data }) => {
          const payload = { room, participants: data };
          commit(actionTypes.INVITE_CHAT_PARTICIPANTS, payload);
          resolve(payload);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.DELETE_CHAT_MESSAGE]({ commit }, { room, id }) {
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/send`, message)
        .then(() => {
          const payload = { room, id };
          commit(actionTypes.DELETE_CHAT_MESSAGE, payload);
          resolve(payload);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.CLOSE_CHAT_ROOM]({ commit }, { room }) {
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/close/${room}`)
        .then(() => {
          commit(actionTypes.CLOSE_CHAT_ROOM, room);
          resolve(room);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.LEAVE_CHAT_ROOM]({ commit }, { room, participants }) {
    if(!message || !room) {
      return;
    }
    const params = participants.map((m) => ({uuid: m, room: { uuid: room }}));

    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/leave/${room}`, params)
        .then(() => {
          const payload = { room, participants: data };
          commit(actionTypes.LEAVE_CHAT_ROOM, payload);
          resolve(paramspayload);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [actionTypes.LOAD_CONTACTS]({ commit }, uuids) {
    if(!uuids) {
      return;
    }
    const params = uuids instanceof Array ? uuids : [uuids];

    return new Promise((resolve, reject) => {
      apiService.get(`${API_PATH}/contacts`, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_CONTACTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.SELECT_CHAT_ROOM]({ commit }, room) {
    commit(actionTypes.SELECT_CHAT_ROOM, room)
  },
  async [actionTypes.CLEAR_SELECTED_CHAT_ROOM]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_CHAT_ROOM)
  },
};

const mutations = {
  [actionTypes.LOAD_CHAT_ROOM](state, payload) {
    state.rooms = [...state.rooms.filter((f) => f.uuid !== payload.uuid), payload];
  },
  [actionTypes.LOAD_CONTACTS](state, payload) {
    state.contacts = [...state.contacts.filter((f) => f.uuid !== payload.uuid), payload];
  },
  [actionTypes.START_CHAT_ROOM](state, payload) {
    state.rooms = [...state.rooms.filter((f) => f.uuid !== payload.uuid), payload];
  },
  [actionTypes.CLOSE_CHAT_ROOM](state, payload) {
    state.rooms = state.rooms.filter((f) => f.uuid !== payload.room);
  },
  [actionTypes.LEAVE_CHAT_ROOM](state, payload) {
    state.rooms = state.rooms.filter((f) => f.uuid !== payload.room);
  },
  [actionTypes.SEND_CHAT_MESSAGE](state, payload) {
    state.rooms = state.rooms.map((m) => {
      if(m.uuid === payload.room.uuid) {
        return {...m, messages: [...m.messages, payload.message]}
      }
      return m;
    })
  },
  [actionTypes.INVITE_CHAT_PARTICIPANTS](state, payload) {
    state.room = {...state.room, participants: [...state.participants.filter((f) =>
      payload.data.map((m) => !m.uuid).includes(f.uuid)), ...payload.data]};
  },
  [actionTypes.OUT_CHAT_PARTICIPANTS](state, payload) {
    state.room = {...state.room, participants: [...state.participants.filter((f) =>
      payload.data.map((m) => !m.uuid).includes(f.uuid))]};
  },
  [actionTypes.DELETE_CHAT_MESSAGE](state, payload) {
    state.messages = state.messages.filter((f) => f.id !== payload.id);
  },
  [actionTypes.DELETE_CHAT_MESSAGE](state, payload) {
    state.messages = state.messages.filter((f) => f.id !== payload.id);
  },
  [actionTypes.SELECT_CHAT_ROOM](state, payload) {
    state.selected = payload;
  },
  [actionTypes.CLEAR_SELECTED_CHAT_ROOM](state) {
    state.selected = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

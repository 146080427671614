import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const BASE_URL = process.env.VUE_APP_API_URL;

class ApiService {
  init(store) {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = BASE_URL;
    Vue.axios.defaults.withCredentials = true;
    Vue.axios.defaults.headers = {
      "Content-type": "application/json",
    };
    Vue.axios.interceptors.request.use(
      function (config) {
        const user = store.state.auth.user;
        const token = user ? user.token : null;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers.Language = store.state.ui.language;
        const csrf = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
        config.headers["X-XSRF-TOKEN"] = csrf;
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );
  }

  readCookie(name) {
    if (!document.cookie) {
      return null;
    }

    const cookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (cookies.length === 0) {
      return null;
    }
    return decodeURIComponent(cookies[0].split('=')[1]);
  }

  query(resource, params) {
    let url = resource;
    if (params instanceof Array) {
      url += "/" + params.join(",");
    } else if (params instanceof Object) {
      // url += "/" + this.toQueryString(params);
      url += this.toQueryString(params);
    }
  return Vue.axios.get(url);
  }

  get(resource, slug) {
    const url = slug ? `${resource}/${slug}` : resource;
    return Vue.axios.get(url);
  }

  file(resource, slug) {
    const url = slug ? `${resource}/${slug}` : resource;
    return Vue.axios.get(url, { responseType: "blob" });
  }

  post(resource, params) {
    return Vue.axios.post(resource, params);
  }

  put(resource, params) {
    return Vue.axios.put(resource, params);
  }

  patch(resource, params) {
    return Vue.axios.patch(resource, params);
  }

  postAndUpload(resource, params, file) {
    const formData = new FormData();
    for(let key in params) { 
      formData.append(key, params[key]);
    }
    formData.append('file', file);

    return Vue.axios.post(resource, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  patchAndUpload(resource, params, file) {
    const formData = new FormData();
    for(let key in params) { 
      formData.append(key, params[key]);
    }
    formData.append('file', file);

    return Vue.axios.patch(resource, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(resource, id) {
    const url = `${resource}/${id}`;
    return Vue.axios.delete(url);
  }

  deleteWithParams(resource, params) {
    const url = `${resource}/${this.toQueryString(params)}`;
    return Vue.axios.delete(url);
  }

  toQueryString(params) {
    let qs = [];
    for (let p in params) {
      if (params[p]) {
        qs.push(`${p}=${params[p]}`);
      }
    }
    return `?${qs.join("&")}`;
  }
}

export const apiService = new ApiService();

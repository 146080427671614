import { apiActions } from "@/services";
import * as actionTypes from "../actions/type.users";
import crud from "../modules/crud";
import { UPDATE_USER_STATE } from "../actions/type.auth";

const users = crud("User", "users");

const state = {
  ...users.state,
  
  dispatchedDrivers: [],
  driversOnDuty: [],

  publishLocation: true,

  onlineUsers: {},
};
const getters = {
  ...users.getters,

  [actionTypes.GET_DISPATCHED_DRIVERS]: (state) => {
    return state.dispatchedDrivers;
  },
  [actionTypes.GET_DRIVERS_ON_DUTY]: (state) => {
    return state.driversOnDuty;
  },
  [actionTypes.GET_LOCATION]: (state) => {
    return state.publishLocation ? state.location : null;
  },

  [actionTypes.GET_ONLINE_USERS]: (state) => {
    return state.onlineUsers;
  },
};

const actions = {
  ...users.actions,

  async [actionTypes.LOAD_ONLINE_USERS]({ commit }) {
    return apiActions.load(
      "users/online",
      null,
      commit,
    actionTypes.LOAD_ONLINE_USERS
    );
  },

  async [actionTypes.LOAD_STORE_USERS_BY_TYPE]({ commit }, params) {
    const { storeId, type } = params;
    return apiActions.load(
      `users/store/${storeId}/type/${type}`,
      null,
      commit,
    actionTypes.LOAD_STORE_USERS_BY_TYPE
    );
  },

  async [actionTypes.SAVE_USER_ROLES]({ commit }, params) {
    const { id } = params;
    return apiActions.update(
      `users/${id}/roles`,
      params,
      commit,
      actionTypes.SAVE_USER_ROLES,
      {
        successMessage: "messages.updateDone",
        // onSuccess: () => {
        //   dispatch(actionTypes.SAVE_USER_ROLES);
        // }
      }
    );
  },

  async [actionTypes.UPDATE_ASSIGNED_STORES]({ commit }, params) {
    return apiActions.update(
      `users/stores`,
      params,
      commit,
      actionTypes.UPDATE_ASSIGNED_STORES,
      {
        successMessage: "messages.updateDone",
        // onSuccess: () => {
        //   dispatch(actionTypes.SAVE_USER_ROLES);
        // }
      }
    );
  },

  async [actionTypes.LOAD_DISPATCHED_DRIVERS]({ commit }) {
    return apiActions.load(
      "users/drivers/active",
      null,
      commit,
    actionTypes.LOAD_DISPATCHED_DRIVERS
    );
  },
  async [actionTypes.SET_DRIVER_STATUS]({ commit, dispatch }, params) {
    const { uuid, status } = params;
    const data = await apiActions.update(
      `users/driver/${uuid}/status/${status}`,
      null,
      commit,
      null,
      {
        successMessage: "messages.updateDone",
        onSuccess: (user) => {
          dispatch(`auth/${UPDATE_USER_STATE}`, { statusName: user.statusName }, { root: true });
        },
      }
    );
    return data;
  },
  async [actionTypes.FORCE_LOGOUT]({ commit }, uuid) {
    return apiActions.action(
      `users/${uuid}/logout`,
      null,
      commit,
      null, //actionTypes.SET_DRIVER_STATUS,
      {
        successMessage: "messages.operationCompleted",
      }
    );
  },
  async [actionTypes.START_TRACK]({ commit }, uuid) {
    return apiActions.action(
      `users/${uuid}/track`,
      null,
      commit,
      null, //actionTypes.SET_DRIVER_STATUS,
      {
        successMessage: "messages.operationCompleted",
      }
    );
  },
  async [actionTypes.BEEP_ALERT]({ commit }, uuid) {
    return apiActions.action(
      `users/${uuid}/beep`,
      null,
      commit,
      null, //actionTypes.SET_DRIVER_STATUS,
      {
        successMessage: "messages.operationCompleted",
      }
    );
  },
  [actionTypes.SET_DRIVERS_ON_DUTY]({ commit }, params) {
    commit(actionTypes.SET_DRIVERS_ON_DUTY, params);
  },
  [actionTypes.PUBLISH_LOCATION]({ commit }) {
    commit(actionTypes.PUBLISH_LOCATION);
  },
  [actionTypes.UNPUBLISH_LOCATION]({ commit }) {
    commit(actionTypes.UNPUBLISH_LOCATION);
  },
};

const mutations = {
  ...users.mutations,

  [actionTypes.LOAD_DISPATCHED_DRIVERS](state) {
    state.loading = true;
  },
  [actionTypes.LOAD_DISPATCHED_DRIVERS_COMPLETED](state, payload) {
    state.loadedSuccessfully = payload.success;
    state.loading = false;
    if(state.loadedSuccessfully) {
      state.items = [...payload.data];
    }
  },
  [actionTypes.LOAD_STORE_USERS_BY_TYPE](state) {
    state.loading = true;
  },
  [actionTypes.LOAD_STORE_USERS_BY_TYPE_COMPLETED](state, payload) {
    state.loadedSuccessfully = payload.success;
    state.loading = false;
    if(state.loadedSuccessfully) {
      state.items = [...payload.data];
    }
  },
  [actionTypes.LOAD_ONLINE_USERS](state, payload) { },
  [actionTypes.LOAD_ONLINE_USERS_COMPLETED](state, payload) {
    state.onlineUsers = payload.data;
  },
  [actionTypes.SAVE_USER_ROLES]() { },
  [actionTypes.SAVE_USER_ROLES_COMPLETED](state, payload) {
    if (payload) {
      state.items = state.items.map((item) => {
        if (item.id === payload.id) {
          return { ...item, roles: payload.roles };
        }
        return item;
      });
    }
  },

  [actionTypes.UPDATE_ASSIGNED_STORES]() { },
  [actionTypes.UPDATE_ASSIGNED_STORES_COMPLETED](state, payload) {
    if (payload) {
      state.items = state.items.map((item) => {
        if (item.id === payload.id) {
          return { ...item, assignedStoreIds: payload.assignedStoreIds };
        }
        return item;
      });
    }
  },
  [actionTypes.SET_DRIVERS_ON_DUTY](state, payload) {
    state.driversOnDuty = payload;
  },
  [actionTypes.PUBLISH_LOCATION](state) {
    state.publishLocation = true;
  },
  [actionTypes.UNPUBLISH_LOCATION](state) {
    state.publishLocation = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("pwd",
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(reg) {
      console.log("pwd", "Service worker has been registered.");
      setInterval(() => {
        reg.update();
      }, 1000 * 60 * 60); // e.g. hourly checks
    },
    cached() {
      console.log("pwd", "Content has been cached for offline use.");
    },
    updatefound() {
      console.log("pwd", "New content is downloading.");
    },
    updated(reg) {
      console.log("pwd", "New content is available; please refresh.");
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: reg })
      );
    },
    offline() {
      console.log("pwd",
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("pwd", "Error during service worker registration:", error);
    },
  });

  self.addEventListener("fetch", (event) => {
    console.log("pwd", "fetching cache...");
    
    const apiUrl = `${process.env.API_BASE_URL}/files/image/`;
    const requestUrl = event.request.url;

    if (requestUrl.includes(apiUrl)) {
      event.respondWith(
        caches.match(event.request).then((response) => {
          if (response) {
            return response;
          }

          return fetch(event.request).then((fetchResponse) => {
            if (fetchResponse.ok) {
              const cacheClone = fetchResponse.clone();
              const cacheExpiration = 60 * 60 * 24 * 10; // 10 days in seconds
              const cacheHeaders = new Headers(fetchResponse.headers);
              cacheHeaders.append("Cache-Control", `max-age=${cacheExpiration}`);

              caches.open("image-cache").then((cache) => {
                const cacheResponse = new Response(cacheClone.body, {
                  status: fetchResponse.status,
                  statusText: fetchResponse.statusText,
                  headers: cacheHeaders,
                });

                cache.put(event.request, cacheResponse);
              });
            }

            return fetchResponse;
          });
        })
      );
    }
  });
}

import store from "@/store";
// import { LOAD_ROLES } from "@/store/actions/type.roles";
import jwtDecode from "jwt-decode";

class AuthorizationService {
  hasRole = (roleName) => {
    const { user } = store.state.auth;
    return user && user.roles && user.roles.includes(roleName);
  }

  authorized = () => {
    return store.state.auth.isAuthenticated;
  }

  getUserInfo = () => {
    const user = { ...store.state.auth.user };
    delete user.token;
    return user;
  }

  getRoles = () => {
    const { token } = store.state.auth.user;
    const claims = jwtDecode(token);
    return claims.au;
  }

  allowed = (permission) => {
    const { isAuthenticated } = store.state.auth;
    const userRoles = this.getRoles();
    
    if (isAuthenticated && userRoles.includes("SUPER-ADMIN")) {
      return true;
    }

    const { roles: aclRoles } = store.state.roles;
    if (!aclRoles || aclRoles.length === 0 || !isAuthenticated) {
      return false;
    }

    const targetRoles = aclRoles.filter((f) => userRoles.includes(f.name));
    if (targetRoles && targetRoles.length > 0) {
      return targetRoles.some(
        (r) =>
          r.permissions &&
          r.permissions.some(
            (p) =>
              p &&
              p.name === permission ||
              p.name.replace(/\s/g, "").toLowerCase() ===
                permission.replace(/\s/g, "").toLowerCase()
          )
      );
    }

    return false;
  }

  generateStoreTopics = (storeId) => {
    const topics = [];
    const { regionId } = store.state.auth.user;
    const roles = this.getRoles();
    if (roles) {
      roles.forEach((role) => {
        const group = role.toLowerCase();
        topics.push({ regionId, storeId, group });
      });
    }
    return topics;
  }
}

export const authorizationService = new AuthorizationService();

export const CONNECT = "connect";
export const CONNECTED = "connected";
export const DISCONNECT = "disconnect";
export const REGISTERED = "registered";
export const SUBSCRIBE = "subscribe";
export const UNSUBSCRIBE = "unsubscribe";
export const MESSAGE = "message";
export const SEND_MESSAGE = "sendMessage";
export const RECEIVED_MESSAGE = "receivedMessage";
export const READ_MESSAGE = "readMessage";
export const DELETE_MESSAGE = "deleteMessage";
export const CLEAR_MESSAGES = "clearMessages";

export const GET_MESSAGES = "getMessages";
export const GET_MESSAGE_BY_ID = "getMessageById";
export const GET_NEW_MESSAGE = "getNewMessage";
export const GET_BACKGROUND_MESSAGES = "getBackgroundMessages";
export const GET_MESSAGES_BY_TOPIC = "getMessageByTopic";

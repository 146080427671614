export const ROOM_DELIVERY = "delivery-room";

export const LOAD_CHAT_ROOM = "loadChatRoom";
export const LOAD_CHAT_MESSAGES = "loadChatMessages";
export const SEND_CHAT_MESSAGE = "sendChatMessage";
export const START_CHAT_ROOM = "startChatRoom";
export const DELETE_CHAT_MESSAGE = "deleteChatMessage";
export const LEAVE_CHAT_ROOM = "leaveChatRoom";
export const CLOSE_CHAT_ROOM = "closeChatRoom";
export const INVITE_CHAT_PARTICIPANTS = "inviteChatParticipant";
export const OUT_CHAT_PARTICIPANTS = "outChatParticipant";
export const LOAD_CONTACTS = "loadContacts";

export const SELECT_CHAT_ROOM = "selectChatRoom";
export const CLEAR_SELECTED_CHAT_ROOM = "clearSelectedChatRoom";

// getters
export const GET_CHAT_ROOMS = "getChatRooms";
export const GET_CHAT_ROOM = "getChatRoom";
export const GET_SELECTED_CHAT_ROOM = "getSelectedChatRoom";
export const GET_CHAT_MESSAGES = "getChatMessages";
export const GET_CONTACTS = "getContacts";

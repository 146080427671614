export const SHOW_FEEDBACK = "showFeedback";
export const DISMISS_FEEDBACK = "dismissFeedback";
export const SHOW_DIALOG = "showDialog";
export const REFRESH_DIALOG = "refreshDialog";
export const CLOSE_DIALOG = "closeDialog";
export const SET_LANGUAGE = "setLanguage";
export const SET_THEME = "setTheme";
export const TOGGLE_FULLSCREEN = "toggleFullscreen";
export const SET_LOADING_STATE = "setLoadingState";
export const CLEAR_LOADING_STATE = "clearLoadingState";
export const ADD_TO_MENU_BADGE = "addToMenuBadge";
export const CLEAR_MENU_BADGE = "clearMenuBadge";
export const SET_OFFLINE = "setOffline";
export const SET_IS_MOBILE = "setIsMobile";
export const SET_HOME_PAGE = "setHomePage";
export const SET_REACTIVITY_PARAMS = "setReactivityParams";
export const CLEAR_REACTIVITY_PARAMS = "clearReactivityParams";
export const REFRESH_REACTIVITY_PARAMS = "refreshReactivityParams";
export const SET_VIEW_DATA = "setViewData";
export const CLEAR_VIEW_DATA = "clearViewData";
// getters
export const GET_REACTIVITY_PARAMS = "getReactivityParams";
export const GET_HOME_PAGE = "getHomePage";
export const GET_COLOR = "getColor";
export const GET_LOADING_STATE = "getLoadingState";
export const GET_LOADING_MESSAGE = "getLoadingMessage";
export const GET_ACTIVE_DIALOG = "getActiveDialog";
export const GET_ACTIVE_NOTIFICATION = "getActiveNotification";
export const GET_LANGUAGE = "getLanguage";
export const GET_NOTIFICATIONS = "getNotifications";
export const GET_MENU_BADGES = "getMenuBadges";
export const GET_MENU_BADGES_COUNTER = "getMenuBadgesCounter";
export const GET_OFFLINE = "getOffline";
export const IS_MOBILE = "isMobile";
export const GET_VIEW_DATA = "getViewData";

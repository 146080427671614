import * as actionTypes from "../actions/type.nav";

const state = {
  history: [],
};

const getters = {
  [actionTypes.GET_NAVIGATION_HOSTORY](state) {
    return state.history;
  },
  [actionTypes.GET_BACK_NAVIGATION](state) {
    return state.history.length > 1 ? state.history[state.history.length - 2] : null;
  },
  [actionTypes.GET_NAVIGATION_ROUTE](state) {
    return state.history.length > 0 ? state.history[state.history.length - 1] : null;
  },
};

const actions = {
  [actionTypes.NAVIGATE_FORWARD]({ commit }, page) {
    commit(actionTypes.NAVIGATE_FORWARD, page);
  },
  [actionTypes.NAVIGATE_BACK]({ commit }) {
    commit(actionTypes.NAVIGATE_BACK);
  },
  [actionTypes.RENAVIGATE]({ commit }, page) {
    commit(actionTypes.RENAVIGATE, page);
  },
  [actionTypes.NAVIGATE_TO]({ commit }, pos) {
    commit(actionTypes.NAVIGATE_TO, pos);
  },
  [actionTypes.CLEAR_NAVIGATION]({ commit }) {
    commit(actionTypes.CLEAR_NAVIGATION);
  },
};

const mutations = {
  [actionTypes.NAVIGATE_FORWARD](state, payload) {
    state.history = [...state.history, payload];
  },
  [actionTypes.NAVIGATE_BACK](state) {
    state.history = state.history.slice(0, state.history.length - 1);
  },
  [actionTypes.RENAVIGATE](state, payload) {  
    state.history = [...state.history.slice(0, state.history.length - 1), payload];
  },
  [actionTypes.NAVIGATE_TO](state, pos) {
    const index = state.history.length + pos > 0;
    if(index > 0) {
      state.history = state.history.slice(0, index);
    }
  },
  [actionTypes.CLEAR_NAVIGATION](state) {
    if(state.history.length > 0) {
      state.history = state.history.splice(0, -1);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default {
  app: {
    title: "MAKZANI",
    route: {
      home: "Home",
      affiliations: "Affiliations",
      stores: "Stores",
      store: "Store",
      shopping: "Shopping",
      section: "Section",
      sections: "Sections",
      categories: "Categories",
      about: "About",
      Intro: "Introduction",
      cart: "Cart",
      favorites: "Favorites",
      orders: "My Orders",
      offers: "Offers",
      profile: "Profile",
      accountSettings: "Account Settings",
      businessSettings: "Business Settings",
      manager: "Manager",
      stats: "Stats",
      settings: "Settings",
      savedAddresses: "Saved Addresses",
      changePassword: "Change Password",
      changeEmail: "Change Email Address",
      address: "Address",
      regions: "Regions",
      stores: "Stores",
      region: "Region",
      store: "Store",
      managedStores: "Managed Stores",
      storeProfile: "Store Profile",
      storeProfiles: "Store Profiles",
      products: "Store Products",
      dispatchers: "Order Dispatchers",
    },
  },
  admin: {
    route: {
      management: "Management",
      admin: "Administrative",
      dashboards: "Dashboards",
      customers: "Customers",
      ordersDispatcher: "Orders Dispatcher",
      dispatchedOrders: "Dispatched Orders",
      orders: "Orders",
      control: "Administrative Control",
      products: "Products",
      regions: "Regions",
      users: "Users",
      roles: "Access Roles",
      offers: "Offers Manager",
      notifications: "Notifications Settings",
      dataTypes: "Data Types",
      regionsStores: "Regions / Stores",
    },
  },
  cart: {
    itemsPrice: "Items Price",
    deliveryFees: "Delivery Fees",
    grandTotal: "Grant Total",
    orderSummary: "Order Summary",
    messages: {
      locked: "There're Cart items from another store, do you want to go to the original store or clear the cart for new order?",
      addressLocked: "Your active address is out of this store's area of service, please select another address.",
      clearAction: "Are you sure you want to clear cart items?",
    }
  },
  product: {
    editor: "Product Editor",
    section: "Section",
    category: "Category",
    sectionCategory: "Section/Category",
    unit: "Unit",
    price: "Price",
    quantity: "Quantity",
    stock: "Stock",
    tags: "Tags",
    images: "Product Images",
    new: "New Product",
    edit: "Edit Product",
    barcode: "Barcode",
    messages: {
      productNoLongerAvailable: "Sorry, this item is no longer avaiabe in this store.",
    }
  },
  region: {
    currency: "Currency",
    new: "New Region",
    edit: "Edit Region",
    initials: "Initials",
  },
  store: {
    region: "Region",
    owner: "Owner",
    logo: "Logo",
    banner: "Banner",
    sections: "Store Sections",
    categories: "Store Categories",
    new: "New Store",
    edit: "Edit Store",
    minimumOrderPrice: "Minimum Order Price",
    deliveryFees: "Delivery Fees",
    freeDeliveryPrice: "Free Delivery Price",
    address: "Address",
    enter: "Enter Store",
    aos: "Area of Serivice",
    active: "Active",
    status: {
      storeStatusOpen: "Open",
      storeStatusClosed: "Closed",
      storeStatusPending: "Pending",
      storeStatusSoon: "Soon",
      storeStatusTemporarilySuspended: "Temporarily Suspended",
    },
    section: {
      edit: "Edit Section",
      new: "New Section",
    },
    messages: {
      noManagedStores: "No store profiles added to your account yet",
    }
  },
  favorites: {
    messages: {
      noItems: "No favorite items selected yet",
    },
  },
  customer: {
    info: "Customer Information",
  },
  driver: {
    info: "Driver Information",
    drivers: "Drivers",
    route: {
      activeDelivery: "Active Delivery",
    },
  },
  order: {
    details: "Order Details",
    update: {
      customer: {
        waiting: "Order in waiting list",
        cancelled: "You have cancelled your order",
        noAccepted:
          "Your order has been rejected due to temporary work suspension",
        preparing: "Your order is being prepared",
        loading: "Order is being loaded for delivery",
        dispatched: "Orders are out for delivery",
        delivery: "Your order is on the way",
        near: "Get ready! The order is near your doorstep",
        delivered: "Order was delivered successfully",
        return: "Order is being returned to the store",
      },
      admin: {
        waiting: "New order received",
        cancelled: "An order has been cancelled",
        delivery: "Driver activated DELIVERY mode",
        returned: "Order has been returned to the store",
      },
      manager: {
        waiting: "New order received",
        cancelled: "An order has been cancelled",
        delivered: "Order was delivered successfully",
        returned: "Order has been returned to the store",
      },
      handler: {
        waiting: "Order received",
        loading: "Loading order to delivery vehicle",
      },
      driver: {
        preparing: "Order went back to preparing list",
        loading: "Loading order to delivery vehicle",
        dispatched: "Orders dispatched for delivery",
        delivery: "Order selected for next delivery",
        near: "You are about to reach the customer's location",
        delivered: "You have successfully delivered the order",
        return: "Order is being returned to the store",
        returned: "Order has been returned to the store",
      },
    },
    toAddress: "To Address",
    changeStatus: "Change Status",
  },
  general: {
    id: "ID",
    code: "Code",
    name: "Name",
    nameAr: "Arabic Name",
    description: "Description",
    date: "Date",
    time: "Time",
    year: "Year",
    action: "Action",
    type: "Type",
    title: "Title",
    status: "Status",
    search: "Search",
    categorize: "Categorize",
    filter: "Filter",
    message: "Message",
    export: "Export",
    add: "Add",
    new: "New",
    edit: "Edit",
    change: "Change",
    save: "Save",
    delete: "Delete",
    undo: "Undo",
    validate: "Validate",
    enable: "Enable",
    disable: "Disable",
    activated: "Activated",
    readonly: "Read Only",
    keepOpen: "Keep Open",
    total: "Total",
    grandTotal: "Grand Total",
    page: "Page",
    goto: "Go To",
    all: "All",
    refresh: "Refresh",
    recache: "Recache",
    close: "Close",
    closeOthers: "Close Others",
    closeAll: "Close All",
    pleaseSelect: "Please Select",
    remarks: "Remarks",
    cancel: "Cancel",
    clear: "Clear",
    dismiss: "Dismiss",
    deselect: "Deselect",
    okay: "Okay",
    reset: "Reset",
    copyLink: "Copy Link",
    confirm: "Confirm",
    create: "Create",
    fileInput: "File Input",
    imageFile: "Image File",
    areas: "Areas",
    expandAll: "Expand All",
    collapseAll: "Collapse All",
    startDate: "Start Date",
    from: "From",
    to: "To",
    send: "Send",
    subject: "Subject",
    details: "Details",
    filesManager: "Files Manager",
    selectImages: "Select Images",
    selectImage: "Select Image",
    active: "Active",
    inactive: "Inactive",
    available: "Available",
    unavailable: "Unavailable",
    logo: "Logo",
    imageFile: "Image File",
    latitude: "Latitude",
    longitude: "Longitude",
    next: "Next",
    back: "Back",
    phone: "Phone",
    restart: "Restart",
    update: "Update",
    icon: "Icon",
  },
  login: {
    title: "Login Form",
    login: "Login",
    loginByPhone: "Login by Phone",
    loginByEmail: "Login by Email",
    sendPin: "Send PIN",
    resendPin: "Resend PIN",
    logout: "Log Out",
    register: "Register",
    changeNumber: "Change Number",
    username: "Username",
    usernameOrEmail: "Username or Email",
    password: "Password",
  },
  user: {
    email: "Email",
    newEmail: "New Email Address:",
    confirmEmail: "Confirm Email",
    fullName: "Full Name",
    username: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    position: "Position",
    phoneNumber: "Phone Number",
    address: "Address",
    avatar: "Avatar",
    avatarFile: "Avatar File",
    role: "Role",
    roles: "Roles",
    assignedStores: "Assigned Stores",
    new: "New User",
    edit: "Edit User",
    ac: "Access Control",
    active: "Active",
    skipConfirmation: "Skip Confirmation",
    updatePassword: "Update Password",
    password: "Password",
    pinCode: "Pin Code",
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
    track: "Track",
  },
  role: {
    new: "New Role",
    edit: "Edit Role",
    permissions: "Role Permissions",
  },
  affiliations: {
    sendInvitation: "Send Invitation",
    accept: "Accept",
    cancel: "Cancel",
  },
  messages: {
    newVersion: "New version available.",
    loading: "Loading Data...",
    loadingProduct: "Loading product data...",
    initializingProduct: "Initializing new product...",
    loadingRole: "Loading role data...",
    initializingRole: "Initializing new role...",
    loadingUser: "Loading user data...",
    initializingUser: "Initializing new user...",
    updateCompleted: "Update operation has completed successfully",
    done: "Done",
    updateDone: "Done",
    createCompleted: "Create operation has completed successfully",
    deleteCompleted: "Delete operation has completed successfully",
    deleteRequest: "Delete request has been sent",
    cancelDeleteRequest: "Delete request has been cancelled",
    accessDenied: "Access Denied",
    sessionEnded: "Your session has expired. Please try login again",
    wrongCredentials:
      "The username and/or password might be incorrect or your account might be deactivated",
    wrongPinCredentials:
      "The phone number and/or PIN-code might be incorrect or your account might be deactivated",
    wrongInputs: "Wrong inputs",
    invalidConfirmationPin: "Invalid confirmation pin code",
    invalidConfirmationCode: "Invalid email confirmation code",
    downloadNotCompleted: "Download operation not completed",
    errorConflictUser: "There might be a user with that same email or username",
    errorConflictPhone: "This phone number has already been registered",
    updateNotSuccessful: "This update was not successful",
    operationError: "Error while executing this operation",
    conflictUser: "User with the same username or email already existed",
    validation:
      "Incorrect or missing inputs, please check validation messages in the form",
    searchCriteria:
      "Select search criteria or leave it empty to bring all data.",
    checkEmailInbox:
      "An email has been sent to you. please check your email inbox.",
    checkSms: "Please check your SMS messages for PIN code",
    passwordUpdated: "Password has been reset successfully.",
    passwordUpdatedFailed:
      "Reset password failed. Reset code might be expired.",
    phoneUpdatedFailed:
      "Changing phone number failed. The new number is probably being used by another account.",
    orderTrackUpdated: "Order track changed",
    orderTrackUpdatedWAITING: "Order is in WAITING list.",
    orderTrackUpdatedPREPARING: "PREPARING the order.",
    orderTrackUpdatedLOADING: "LOADING in delivery vehicle.",
    orderTrackUpdatedDISPATCHED: "Out for delivery.",
    orderTrackUpdatedDELIVERY: "On the way to you.",
    orderTrackUpdatedNEAR: "Near your doorstep, please get ready.",
    orderTrackUpdatedDELIVERED: "Nice, your order has been delivered.",
    orderTrackUpdatedSWITCH: "SWITCHING drivers.",
    orderTrackUpdatedRETRUNED: "Failed to delivered, order is being returned.",
    orderTrackUpdatedCANCELLED: "Order has been CANCELLED.",
    orderTrackUpdatedNOT_ACCEPTED: "We're Sorry, the store is currently busy.",
    selectDrivers: "Select the drivers on duty",
    selectDriver: "Select the driver for loading the order",
    selectImages: "Browse and select images",
  },
  errors: {
    error: "Error",
    outOfStock: "The available quantity of this product is ({available}). Do you want to change your order?",
    newOutOfStock:
      "[{productName}] item is almost out of stock.. only {available} item(s) are available currently",
    addressNotFound: "Address not found",
    belowMinimum: "Order price is less than minimum",
    roleConflict: "A role with the same name [{getName}] is already exist",
  },
  settings: {
    dataTypes: {
      group: "Group",
      items: "Items",
      unitTypes: "Unit Types",
      tags: "Tags",
    },
  },
chat: {
  talkToCustomer: "Talk to Customer: {customerName}",
  talkToDriver: "Talk to Driver: {driverName}",
},
};

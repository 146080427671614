export const SCAN_ITEM = "scanItem";
export const RESET_ITEM = "resetItem";
export const SELECT_ITEM = "selectItem";
export const CLEAR_SCANNED_ORDER = "clearScannedOrder";
export const CLEAR_SCANNED_ORDERS = "clearScannedOrders";

// getters
export const GET_ACTIVE_SCAN = "getActiveScan";
export const GET_SCAN_QUNATITY = "getScanQuantity";
export const GET_SCANNED_ITEMS = "getScannedItems";
export const GET_SCANNED_ORDERS = "getScannedOrders";

<template>
  <div
    v-if="updates"
    style="position: absolute; bottom: 0; width: 100%; z-index: 999"
  >
    <v-toolbar color="secondary lighten-3">
      <v-icon color="primary" large>mdi-alert-circle-outline</v-icon>
      <h4 class="primary--text">{{ $t("messages.newVersion") }}</h4>
      <v-btn depressed color="primary" class="mx-5" light @click="$emit('update')">{{
        $t("general.update")
      }}</v-btn>
      <v-spacer />
      <v-btn icon large @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "UpdateBar",
  props: {
    updates: Boolean,
  }
};
</script>
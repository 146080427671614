import { UPDATE_ORDER_LOCATION } from "@/store/actions/type.orders";
import { Geolocation } from "@capacitor/geolocation";

export default {
  install(Vue, { store }) {

    let watcher = null;

    // Define a function to fetch geolocation and dispatch it
    function fetchAndDispatchLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        if (store.state.users.publishLocation) {
          const { latitude, longitude } = position.coords;
          // const latitude = 12.7633881, longitude = 44.8848793;
          console.log("position", latitude, longitude);
          const time = new Date(position.timestamp);
          store.dispatch(`orders/${UPDATE_ORDER_LOCATION}`, {
            latitude,
            longitude,
            time,
          });
        }
      },
        null,
        { enableHighAccuracy: true, timeout: 60000 }
      );
    }


    const permitGeoFunc = () => {
      if (Vue.isNative) {
        Geolocation.checkPermissions().then((status) => {
          if (status.location !== "granted") {
            Geolocation.requestPermissions();
          }
        });
      } else {
        try {
          Geolocation.getCurrentPosition();
        } catch {
          console.log("No geolocation support");
        }
      };
    }

    const toRad = (value) => {
      return (value * Math.PI) / 180;
    };

    const calcCrowFunc = (p1, p2) => {
      var R = 6371; // km
      var dLat = toRad(p2.latitude - p1.latitude);
      var dLon = toRad(p2.longitude - p1.longitude);
      var lat1 = toRad(p1.latitude);
      var lat2 = toRad(p2.latitude);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    };

    const currentPositionFunc = async () => {
      return new Promise((resolve, reject) => {
        if (Vue.isNative) {
          Geolocation.getCurrentPosition()
            .then(() => resolve({ coords: { latitude: 12.755383, longitude: 44.8935468 } }))
            .catch((err) => reject(err));
        } else {
          navigator.geolocation
            .getCurrentPosition((pos) => {
              console.log(pos);
              resolve(pos);
            });
        }
      });
    };

    const positionWatcher = () => {
      fetchAndDispatchLocation();
      return setInterval(fetchAndDispatchLocation, 6000);
    }

    const watchPositionFunc = async () => {
      console.log("call watchPosition", new Date());
      if (
        store.state.auth.user.userType !== "CUSTOMER" &&
        store.state.auth.isAuthenticated &&
        store.state.users.publishLocation
      ) {
        if (process.env.VUE_APP_MODE === "development") {
          if (watcher) {
            console.log("if(watcher) = true", new Date());
            clearInterval(watcher);
          }

          watcher = await positionWatcher();
          return watcher;
        } else {
          if (watcher) {
            console.log("if(watcher) = true", new Date());
            Geolocation.clearWatch(watcher);
          }

          watcher = await Geolocation.watchPosition(
            { enableHighAccuracy: true, timeout: 60000 },
            function (position) {
              if (!position) {
                return;
              }

              if (store.state.users.publishLocation) {
                const { latitude, longitude } = position.coords;
                console.log("position", position, new Date());
                // const latitude = 12.75519;
                // const longitude = 44.89149;
                const time = new Date(position.timestamp);
                store.dispatch(`orders/${UPDATE_ORDER_LOCATION}`, {
                  latitude,
                  longitude,
                  time,
                });
              }
            }
          );
        }
      }
    };

    Vue.watchPosition = watchPositionFunc;
    Vue.currentPosition = currentPositionFunc;
    Vue.permitGeo = permitGeoFunc;
    Vue.calcCrow = calcCrowFunc;
    Vue.mixin({
      methods: {
        $watchPosition() {
          return watchPositionFunc();
        },
        async $currentPosition() {
          return await currentPositionFunc();
        },
        $permitGeo() {
          permitGeoFunc();
        },
        $calcCrow(p1, p2) {
          return calcCrowFunc(p1, p2);
        },
      },
    });
  },
};

<template>
  <v-snackbar
    v-model="getActiveNotification.show"
    :timeout="getActiveNotification.timeout"
    :top="!getActiveNotification.alert"
    :bottom="getActiveNotification.alert"
    :color="getActiveNotification.color"
    :light="getActiveNotification.light"
    :width="getActiveNotification.width || 250"
  >
    <div class="d-flex">
      <span class="pr-2">
        <v-icon small v-if="getActiveNotification.icon">{{
          getActiveNotification.icon
        }}</v-icon>
        <b v-if="getActiveNotification.title">
          {{
            getActiveNotification.skipTranslation
              ? getActiveNotification.title
              : $t(getActiveNotification.title)
          }}
          |
        </b>
        {{
          getActiveNotification.skipTranslation
            ? getActiveNotification.text
            : $t(getActiveNotification.text, getActiveNotification.textParams)
        }}
      </span>
      <v-spacer />
      <v-btn
        depressed
        @click="notificationAction(getActiveNotification.action)"
        v-if="!!getActiveNotification.actionText"
      >
        {{ getActiveNotification.actionText }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PopupBox",
  computed: {
    ...mapGetters("ui", ["getActiveNotification"]),
  },
  methods: {
    ...mapActions("ui", ["dismissFeedback"]),

    notificationAction(action) {
      action();
      this.dismissFeedback();
    },
  },
};
</script>

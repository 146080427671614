import { apiActions } from "@/services";
import * as actionTypes from "../actions/type.categories";
import crud from "./crud";

const categories = crud("Category", "units/categories", "sectionId");

const state = {
  ...categories.state,
};

const getters = {
  ...categories.getters,
};

const actions = {
  ...categories.actions,

  [actionTypes.REFRESH_PRODUCTS]({ commit }, { storeId, productIds }) {
    if (!(productIds instanceof Array)) {
      return;
    }

    return apiActions.load(
      `units/store/${storeId}/products/${productIds.join(",")}`,
      null,
      commit,
      actionTypes.REFRESH_PRODUCTS);
  },

};

const mutations = {
  ...categories.mutations,

  [actionTypes.REFRESH_PRODUCTS](state) {
    state.loading = true;
  },
  [actionTypes.REFRESH_PRODUCTS_COMPLETED](state, payload) {
    state.loading = false;
    if (!payload.success || !(payload.data instanceof Array)) {
      return;
    }
    // search within store categories and categories for matching products to update
    // section > category > product
    state.items = state.items.map((c) => {
      return {
        ...c,
        products: c.products?.map((cp) => {
          const p = payload.data.find((dp) => dp.id === cp.id);
          if (p) {
            return p;
          }

          return cp;
        })
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default {
  app: {
    title: "مخزني",
    route: {
      home: "الرئيسية",
      affiliations: "العلاقات",
      stores: "مخازن",
      store: "مخزن",
      shopping: "التسوّق",
      section: "قسم",
      sections: "أقسام",
      categories: "تصنيفات",
      about: "حول التطبيق",
      Intro: "مقدمة",
      cart: "السلة",
      favorites: "المفضلة",
      orders: "طلبياتي",
      offers: "العروض",
      profile: "سجل المستخدم",
      accountSettings: "اعدادات الحساب",
      businessSettings: "اعدادات العمل التجاري",
      manager: "مدير",
      stats: "قراءات",
      settings: "الاعدادات",
      savedAddresses: "العناوين المحفوظة",
      changePassword: "تغيير كلمة المرور",
      changeEmail: "تغيير البريد الإلكتروني",
      address: "عنوان",
      regions: "أقاليم",
      stores: "مخازن",
      region: "إقليم",
      store: "مخسزن",
      managedStores: "المخازن الدارة",
      storeProfiles: "سجل المخزن",
      storeProfiles: "سجلات المخازن",
      products: "إدراة المنتجات",
      dispatchers: "موزع الطلبات",
    },
  },
  admin: {
    route: {
      admin: "إداري",
      management: "إدارة",
      dashboards: "لوحات المعلومات",
      customers: "المشتركين",
      ordersDispatcher: "تحظير الطلبيات",
      dispatchedOrders: "الطلبيات المرسلة",
      orders: "الطلبيات",
      control: "لوحة التحكم",
      products: "إدراة المنتجات",
      regions: "أقاليم",
      users: "الستخدمين",
      roles: "قواعد الحماية",
      offers: "إدراة العروض",
      notifications: "إعدادات التنبيهات",
      dataTypes: "إنواع البيانات",
      regionsStores: "أقاليم ومخازن",
    }
  },
  product: {
    Section: "القسم",
    category: "التصنيف",
    sectionCategory: "القسم/التصنيف",
    unit: "الوحدة",
    price: "السعر",
    quantity: "الكمية",
    stock: "المخزون",
    tags: "السمات",
    images: "صور المنتج",
    new: "منتج جديد",
    edit: "تحرير منتج",
    barcode: "الباركود",
    messages: {
      productNoLongerAvailable: "نعتذر، هذا المنتج لم يعد متاحاً في هذا المخزن.",
    }
  },
  section: {
    edit: "تحرير قسم",
    new: "قسم جديد",
  },
  cart: {
    itemsPrice: "سعر المنتجات",
    deliveryFees: "تكلفة التوصيل",
    grandTotal: "المجموع الكلي",
    orderSummary: "ملخ الطلب",
    messages: {
      locked: "هناك عناصر سلة مشتريات من مخزن آخر, هل تود الرجوع إلى المخزن المصدر أو تفريغ السلة لطب جديد؟",
      addressLocked: "إن العنوان المحدد لا يقع ضمن نطاق خدمة المخزن هذا, برجى اختيار عنوان آخر.",
      clearAction: "هل أنت متأكد من أنك تريد تفريغ السلة؟",
    }
  },
  region: {
    currency: "العملة",
    new: "إقليم جديد",
    edit: "تحرير إقليم",
    initials: "الأحرف الأولية",
  },
  store: {
    region: "الإقليم",
    owner: "المالك",
    logo: "الشعار",
    banner: "اللوحة",
    sections: "أقسام المخزن",
    categories: "تصنيفات قسم",
    new: "مخزن جديد",
    edit: "تحرير مخزن",
    minimumOrderPrice: "أدنى سعر طلبية",
    deliveryFees: "تكلفة التوصيل",
    freeDeliveryPrice: "سعر التوصيل المجاني",
    address: "العنوان",
    enter: "ادخل المخزن",
    aos: "نطاق الخدمة",
    active: "نشط",
    status: {
      storeStatusOpen: "مفتوح",
      storeStatusClosed: "مغلق",
      storeStatusPending: "العمل معلّق",
      storeStatusSoon: "قريباً",
      storeStatusTemporarilySuspended: "موقف مؤقتاً",
    },
    messages: {
      noManagedStores: "لا توجد سجلات مخازن مرتبطة بحسابك بعد",
    }
  },
  favorites: {
    messages: {
      noItems: "لم يتم اضافة أي منتج إلى قائمة المفضلة بعد",
    },
  },
  customer: {
    info: "معلومات العميل",
  },
  driver: {
    info: "معلومات السائق",
    drivers: "السائقين",
    route: {
      activeDelivery: "الطلبيات النشطة",
    },
  },
  order: {
    details: "تفاصيل الطلبية",
    update: {
      customer: {
        waiting: "أرسل الطلب، سنعلمك حينما يتم قبوله",
        cancelled: "لقد قمت بإلغاء الطلب",
        noAccepted:
          "لم يتم قبول الطلب لتوقف الخدمة مؤقتاُ",
        preparing: "يتم الآن تجهيز طلبك",
        loading: "يتم تحميل الطلب في مركبة التوصيل",
        dispatched: "تم شحن الطلبات",
        delivery: "طلبك الآن في الطريق إليك",
        near: "أصبح طلبك على مقربة منك",
        delivered: "لقد تم توصيل طلبك بنجاح",
        return: "يتم الآن إعادة الطلب إلى المخزن",
      },
      admin: {
        waiting: "طلب جديد",
        cancelled: "تم إلغاء طلب",
        delivered: "تم توصيل الطلب بنجاح",
        returned: "تم إعادة الطلب إلى المخزن",
      },
      manager: {
        waiting: "طلب جديد",
        cancelled: "تم إلغاء طلب",
        delivered: "تم توصيل الطلب بنجاح",
        returned: "تم إعادة الطلب إلى المخزن",
      },
      handler: {
        waiting: "تم استلام طلب",
        loading: "يتم تحميل الطلب على مركبة التوصيل",
      },
      driver: {
        preparing: "عادت الطلبية إلى قائمة الإعداد",
        loading: "يتم تحميل الطلب على مركبة التوصيل",
        dispatched: "بدء عملية توصيل الطلبات",
        delivery: "تم اختيار الطب للتوصيل التالي",
        near: "أنت على وشك الوصول إلى العميل",
        delivered: "لقد قمت بإيصال الطلب بنجاح",
        return: "يتم الآن إعادة الطلب إلى المخزن",
        returned: "تم إعادة الطلب إلى المخزن",
      },
    },
    toAddress: "إلى العنوان",
    changeStatus: "تغيير الحالة",
  },
  general: {
    id: "رمز",
    code: "الرمز",
    name: "الاسم بالإنجليزي",
    nameAr: "الاسم",
    description: "الوصف",
    event: "حدث",
    date: "التاريخ",
    time: "الوقت",
    year: "السنة",
    action: "الحدث",
    type: "النوع",
    title: "العنوان",
    status: "الحالة",
    search: "بحث",
    categorize: "تنظيم",
    filter: "تصفية البيانات",
    message: "الرسالة",
    export: "تصدير",
    add: "إضافة",
    new: "جديد",
    edit: "تحرير",
    change: "تغيير",
    save: "حفظ",
    delete: "حذف",
    undo: "تراجع",
    validate: "تحقق",
    enable: "تفعيل",
    disable: "إلغاء التفعيل",
    activated: "مفعّل",
    readonly: "للقراءة فقط",
    keepOpen: "لا تغلق النافذة",
    total: "المجموع",
    grandTotal: "المجموع الكلي",
    page: "الصفحة",
    goto: "الانتقال إلى",
    all: "الكل",
    refresh: "إنعاش",
    recache: "إعادة التخزين",
    close: "أغلق",
    closeOthers: "أغلق الأخريات",
    closeAll: "أغلق الكل",
    pleaseSelect: "الرجاء الاختيار",
    remarks: "ملاحظات",
    cancel: "ألغاء",
    clear: "محو",
    dismiss: "تمت الملاحظة",
    deselect: "إلغاء التحديد",
    okay: "موافق",
    reset: "إعادة ضبط",
    copyLink: "نسخ الرابط",
    confirm: "تأكيد",
    create: "إنشاء",
    fileInput: "ملف",
    areas: "المناطق",
    expandAll: "تصغير الكل",
    collapseAll: "تكبير الكل",
    startDate: "تاريخ البدء",
    from: "من",
    to: "إلى",
    details: "تفاصيل",
    filesManager: "إدارة الملفات",
    selectImages: "اختر الصور",
    active: "مفعل",
    inactive: "غير مفعّل",
    available: "مفعل",
    unavailable: "غير متاح",
    logo: "الشعار",
    imageFile: "ملف صورة",
    latitude: "خط العرض",
    longitude: "خط الطول",
    next: "التالي",
    back: "السابق",
    phone: "الهاتف",
    restart: "تنشيط",
    update: "تحديث",
  },
  login: {
    title: "استمارة الدخول",
    login: "الدخول",
    loginByPhone: "الدخول عبر الهاتف",
    loginByEmail: "الدخول عبر البريد",
    sendPin: "ارسل الرمز",
    resendPin: "إعادة إرسال الرمز",
    logout: "الخروج",
    register: "تسجيل",
    changeNumber: "تغيير رقم الهاتف",
    username: "اسم المستخدم",
    usernameOrEmail: "اسم المستخدم أو البريد",
    password: "كلمة المرور",
  },
  user: {
    email: "بريد",
    newEmail: "البريد الإلكتروني الجديد",
    confirmEmail: "تأكيد البريد",
    fullName: "الاسم الكامل",
    username: "اسم المستخدم",
    firstName: "الاسم الأول",
    lastName: "اللقب",
    position: "الوظيفة",
    phoneNumber: "رقم الهاتف",
    address: "العنوان",
    avatar: "صورة",
    avatarFile: "ملف الصورة",
    role: "دور",
    roles: "أدوار الحماية",
    assignedStores: "المخازن المعينة",
    new: "مستخدم جديد",
    edit: "تحرير مستخدم",
    ac: "تحكم الأمان",
    active: "مفعّل",
    skipConfirmation: "تجاهل التأكيد",
    updatePassword: "تغيير كلمة المرور",
    password: "كلمة المرور",
    pinCode: "كود مرمز",
    confirmPassword: "تأكيد كلمة المرور",
    newPassword: "كلمة المرور جديدة",
    track: "تعّقب",
  },
  affiliations: {
    sendInvitation: "إرسال دعوة",
    accept: "قبول",
    cancel: "إلغاء",
  },
  role: {
    new: "مجموعة حماية جديدة",
    edit: "تحرير مجموعة حماية",
    permissions: "الصلاحيات",
  },
  messages: {
    newVersion: "إصدار جديد متوفر.",
    loading: "تحميل البيانات",
    loadingProduct: "تحميل بيانات منتج",
    initializingProduct: "انشاء سجل منتج جديدة",
    loadingRole: "تحميل بيانات قاعدة حماية",
    initializingRole: "انشاء سجل قاعدة حماية جديدة",
    loadingUser: "تحميل بيانات مستخدم",
    initializingUser: "انشاء سجل مستخدم جديد",
    updateCompleted: "عملية التحديث تمت بنجاح",
    done: "تم",
    updateDone: "تم",
    createCompleted: "عملية الإنشاء تمت بنجاح",
    deleteCompleted: "عملية الحذف تمت بنجاح",
    deleteRequest: "تم إرسال طلب الحذف",
    cancelDeleteRequest: "تم إلغاء طلب الحذف",
    accessDenied: "دخول ممنوع",
    sessionEnded: "مدة جلسة الدخول المتاحة إنتهت. يرجى معاودة الدخول",
    wrongCredentials: "قد يكون اسم مستخدم و/أو كلمة مرور خاطئة. وربما يكون حسابك غير مفعل بعد",
    wrongPinCredentials: "قد يكون رقم الهاتف و/أو رمز الدخول خاطئ. وربما يكون حسابك غير مفعل بعد",
    wrongInputs: "مدخلات خاطئة",
    invalidConfirmationPin: "رمز تأكيد رقم الهاتف خاطئ",
    invalidConfirmationCode: "رمز تأكيد بريد خاطئ",
    downloadNotCompleted: "عملية تنزيل ملف غير مكتملة",
    errorConflictUser: "لعل هناك مستخدم بذات الاسم أو رمز المستخدم",
    errorConflictPhone: "لقد تم تسجيل هذا الرقم مسبقاً",
    updateNotSuccessful: "هذا التحديث لم ينجح",
    operationError: "خطأ أثناء تنفيذ هذه العملية",
    conflictUser: "مستخدم بنفس الرمز أو البريد موجودٌ مسبقاً",
    validation: "مدخلات خاطئة أو مفقودة، يرجى التحقق من رسائل التحقق من صحة البيانات في الاستمارة",
    searchCriteria: "حديد معايير البحث أو اتركها خالية لجب جميع البيانات.",
    checkEmailInbox: "تم إرسال رسالة إلى بريدك. الرجاء مراجعة الرسائل الواردة في بريدك.",
    checkSms:
      "الرجاء مراجعة رسائل الهاتف ونسخ رمز الدخول.",
    passwordUpdated: "تم إعادة ضبط كلمة المرور بنجاح.",
    passwordUpdatedFailed: "لم تتم إعادة ضبط كلمة المرور. لعل رمز إعادة الضبط منتهي الصلاحية.",
    phoneUpdatedFailed:
      "فشل عملية تغيير رقم الهاتف. لعل الرقم الجدبد مسجل من قبل حساب آخر.",
    notDefaultAddress:
      "لم يتم تحديد أي عنوان  أفتراضي, تستطيع أضافة واحد من الاعدادات.",
    orderTrackUpdated: "تحديث في حالة الطلبية",
    orderTrackUpdatedWAITING: "الطلب في قائمة الانتظار.",
    orderTrackUpdatedPREPARING: "جاري تجهيز الطلب.",
    orderTrackUpdatedLOADING: "تحميل الطلب في مركبة التوصيل.",
    orderTrackUpdatedDISPATCHED: "خروج الطلب للتوصيل.",
    orderTrackUpdatedDELIVERY: "في الطريق إليك.",
    orderTrackUpdatedNEAR: "بالقرب من باب منزلك، يرجى الاستعداد.",
    orderTrackUpdatedDELIVERED: "تم توصيل الطلب بنجاح.",
    orderTrackUpdatedSWITCH: "جاري تغيير السائق.",
    orderTrackUpdatedRETRUNED: "فشل التوصيل، جاري استعادة الطلب.",
    orderTrackUpdatedCANCELLED: "تم إلغاء الطلب.",
    orderTrackUpdatedNOT_ACCEPTED: "عذرًا، المتجر مشغول حاليًا.",
    selectDrivers: "اختر السائقين في وضعة العمل",
    selectDriver: "اختر السائق لتحميل الطلب",
    selectImages: "تصفح واخر الصور",
  },
  errors: {
    error: "خطأ",
    outOfStock: "إن الكية المتوفرة من هذا المنتج هي ({available}). هل تود تعديل الطلب؟.",
    newOutOfStock: "النتج {productName} شارف على النفاذ.. لم يتمبقى سوى {available} منه الآن",
    addressNotFound: "لم يتم العثور على العنوان",
    belowMinimum: "سعر الطلبية أقل من الحد الأدنى",
    roleConflict: "قاعدة حماية باسم [{getName}] موجودة مسبقاً"
  },
  settings: {
    dataTypes: {
      group: "محموعة",
      items: "عناصر",
      unitTypes: "وحدات الفياس",
      tags: "السمات",
    },
  },
  chat: {
    talkToCustomer: "تحدث إلى العميل: {customerName}",
    talkToDriver: "تحدث إلى الكابتن: {driverName}",
  },
};

import { CHECK_AUTH, LOGOUT } from "@/store/actions/type.auth";
import {
  SHOW_DIALOG,
  CLOSE_DIALOG,
  SHOW_FEEDBACK,
  DISMISS_FEEDBACK,
} from "@/store/actions/type.ui";
import { OPEN_STORE } from "@/store/actions/type.stores";
import { SUBSCRIBE, UNSUBSCRIBE } from "@/store/actions/type.socket";
import { CLEAR_VIEW_DATA } from "@/store/actions/type.ui";
// import { 
//   NAVIGATE_FORWARD,
//   NAVIGATE_BACK,
//   CLEAR_NAVIGATION,
//   GET_BACK_NAVIGATION,
//   GET_NAVIGATION_HOSTORY 
// } from "@/store/actions/type.nav";
import util from "@/common/helpers/util";
import { CLEAR_NAVIGATION } from "../store/actions/type.nav";

export default {
  install(Vue, { store, router }) {
    let isBackButtonPressed = false;
    const historyStack = []; // Custom history stack

    Vue.prototype.$$route = function () {
      return this.$route;
    }

    window.addEventListener('popstate', () => {
      isBackButtonPressed = true;
    });

    const getHomeRoute = () => {
      const homePages = [
        { type: ["CUSTOMER", "*"], path: "/stores", name: "stores" },
        { type: ["DRIVER"], path: "/delivery/active", name: "delivery.active" },
        { type: ["MERCHANT"], path: "/manager/stats", name: "manager.stats" },
        { type: ["MANAGER", "ORDER-HANDLER"], path: "/manager/store/dispatchers", name: "manager.store.dispatchers" },
        { type: ["ADMIN"], path: "/admin/users", name: "admin.users" },
      ];
      const accountType = process.env.VUE_APP_CLIENT_TYPE || window.localStorage.getItem("type") || "*";
      const home = homePages.find((f) => f.type.includes(accountType));
      return home ? home : homePages[0];
    }

  
    const controlRouteFunc = (to, from, next) => {

      store.dispatch(`ui/${CLEAR_VIEW_DATA}`);
      Vue.unsubAll();

      console.log("isBackButtonPressed:", isBackButtonPressed);
      console.log("to:", to);

      if (from.name && to.name === from.name && util.compareObjects(to.query, from.query)) {
        return;
      }

      const homeRoute = getHomeRoute();
      if (
        (to.name === "default" || to.path === "/stores") &&
        (to.name !== homeRoute.name || to.fullPath !== homeRoute.path)) {
        router.replace({ name: homeRoute.name });
        return;
      }

      if (to.matched.length === 0) {
        // setTimeout(() => {
        //   const notification = {
        //     text: "Resource not found",
        //     actionText: "OK",
        //     action: () => {
        //       store.dispatch(`ui/${DISMISS_FEEDBACK}`);
        //     },
        //   };
        //   store.dispatch(`ui/${SHOW_FEEDBACK}`, notification);
        // }, 500);

        if (!from.name) {
          router.push({ name: "error.404" });
        } else {
          return;
        }
      }

      if (!!to.meta.resource) {
        if (!store.state.auth.isAuthenticated && to.name !== "login" && to.name !== "alt-login") {
          router.replace({ name: "login" });
        }

        const rolesAuthorized =
          to.meta.resource.indexOf("{route}") === -1
            ? Vue.allowed(to.meta.resource)
            : Vue.allowed(
              to.meta.resource.replace(
                "{route}",
                to.params.route.replace("-", ".")
              )
            );

        if (!rolesAuthorized && to.name !== "login" && to.name !== "alt-login") {
          if (
            (to.name === "pin" &&
              store.state.auth.user.phoneConfirmed) ||
            (to.name === "email" &&
              store.state.auth.user.emailConfirmed)
          ) {
            router.replace({ name: "default" });
          } else if (to.name === "default" || !to.name) {
            router.push({ name: "login" });
          } else if (to.redirectedFrom !== "/") {
            const notification = {
              text: "messages.accessDenied",
              actionText: "OK",
              action: () => {
                store.dispatch(`ui/${DISMISS_FEEDBACK}`);
                router.replace({ name: "default" });
              },
            };
            store.dispatch(`ui/${SHOW_FEEDBACK}`, notification);
            // if (from.name || from.name !== "login" || from.name !== "alt-login") {
            //   router.go(-1);
            // }
          }
        } else if (!store.state.auth.user.phoneConfirmed) {
          // TODO: go to message "confirm phone number"
          store.dispatch(`auth/${LOGOUT}`);
          // router.push({ name: "login" });
        } else if (
          to.name !== "region" &&
          !store.state.auth.user.regionId
        ) {
          router.push({
            name: "region",
            params: { back: false },
          });
        } else if (
          store.state.auth.user.userType === "customer" &&
          store.state.auth.user.regionId &&
          to.name !== "addresses" &&
          to.name !== "address" &&
          !store.state.auth.user.activeAddress
        ) {
          router.push({
            name: "addresses",
            params: { back: false, initAccount: true },
          });
        } else {
          const closeStoreId = from.params ? parseInt(from.params.storeId) : null;
          const openStoreId = to.params ? parseInt(to.params.storeId) : null;
          store.dispatch(`stores/${OPEN_STORE}`, openStoreId, { root: true });
          if (closeStoreId && closeStoreId !== openStoreId) {
            // in case of switching beteen stores
            store.dispatch(`socket/${UNSUBSCRIBE}`, [{ storeId: closeStoreId }], { root: true });
          } else if (openStoreId) {
            // in case of navigating to a store
            store.dispatch(`socket/${SUBSCRIBE}`, [{ storeId: openStoreId }], { root: true });
          }

          isBackButtonPressed = false;
        }

        // store
        //   .dispatch(`auth/${CHECK_AUTH}`)
        //   .then(() => {
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else if (
        store.state.auth.isAuthenticated &&
        (to.name === "login" ||
          to.name === "alt-login" ||
          to.name === "register")
      ) {
        const dialog = {
          title: "Logged In",
          text:
            "You are currently logged in. Do you want to log out?",
          actions: [
            {
              text: "Yes",
              click: () => {
                store.dispatch(`auth/${LOGOUT}`);
                store.dispatch(`ui/${CLOSE_DIALOG}`);

                isBackButtonPressed = false;
                next(false);
              },
              color: "primary",
            },
            {
              text: "No",
              click: () => {
                store.dispatch(`ui/${CLOSE_DIALOG}`);
                if (to.name !== "login" || to.name !== "alt-login") {
                  router.go(-1);
                }
              },
              color: "primary",
            },
          ],
        };

        store.dispatch(`ui/${SHOW_DIALOG}`, dialog);
      } else {
        const loginPhone = window.localStorage.getItem("loginPhone");
        const registerPhone = window.localStorage.getItem("registerPhone");

        if (to.name === "pin" && !loginPhone && !registerPhone) {
          router.push({ name: "login" });
        } else if (to.name === "login" && !!loginPhone) {
          router.push({
            name: "pin",
            query: { phone: loginPhone, isLogin: true },
          });
        } else if (to.name === "register" && !!registerPhone) {
          router.push({
            name: "pin",
            query: { phone: registerPhone, registrationType: "phone" },
          });
        } else {
          isBackButtonPressed = false;
        }
      }

      if (to.matched.length === 0) {
        historyStack.push({
          path: from.fullPath,
          name: from.name,
          params: from.params,
          query: from.query
        });
      }
      next();
    };

    router.beforeEach(controlRouteFunc);

    Vue.prototype.$$route = {
      query: router.query,
      params: router.params,
    }

    Vue.prototype.$$router = {
      push(location) {
        router.push(location);
        // store.dispatch(`nav/${NAVIGATE_FORWARD}`, Vue.$$route);
      },
      replace(location) {
        router.replace(location);
        // store.dispatch(`nav/${RENAVIGATE}`, Vue.$$route);
      },
      go(n) {
        router.go(n);
        // store.dispatch(`nav/${NAVIGATE_TO}`, n);
      },
      back(n = 1) {
        if (n >= 0 && n <= historyStack.length) {
          // historyStack.splice(-n);
          router.go(-n);
        }
      },
      clear() {
        // historyStack.splice(0, -1);
        // store.dispatch(`nav/${CLEAR_NAVIGATION}`);
      },
      getHistory() {
        return historyStack;
      }
    };
  },
};

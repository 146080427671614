import Vue from "vue";
import router from "@/router";
import { apiService, apiActions } from "@/services";
import * as actionTypes from "../actions/type.auth";
import { SET_ROLES } from "../actions/type.roles";
import { CLEAR_STORES } from "../actions/type.stores";
import { REFRESH_REACTIVITY_PARAMS } from "../actions/type.ui";
const API_PATH = "auth";
import jwtDecode from 'jwt-decode';

const state = {
  user: {},
  isAuthenticated: false,
};

const getters = {
  [actionTypes.GET_CURRENT_USER](state) {
    return state.user;
  },
  [actionTypes.IS_AUTHENTICATED](state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [actionTypes.LOGIN]({ commit, dispatch }, params) {
    const { type, principal, password } = params;
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/${type}`, { principal, password })
        .then(({ data }) => {
          const claims = jwtDecode(data.token);
          const roles = claims.au;
          const user = { ...data, roles };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          resolve(user);
        })
        .catch(({ response }) => {
          Vue.popup("messages.wrongCredentials", "error");
          reject(response);
        });
    });
  },
  [actionTypes.PERMITTED]({}, { principal, password, permission }) {
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/permitted`, { principal, password, permission })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          Vue.popup("messages.wrongCredentials", "error");
          reject(false);
        });
    });
  },
  async [actionTypes.LOGOUT]({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/logout`)
        .then(() => {
          dispatch(`stores/${CLEAR_STORES}`, null, { root: true });
          commit(actionTypes.PURGE_AUTH);
          router.push({ name: "login" });

          resolve();
        })
        .catch(() => {
          dispatch(`stores/${CLEAR_STORES}`, null, { root: true });
          commit(actionTypes.PURGE_AUTH);
          router.push({ name: "login" });

          reject();
        });
    });
  },
  [actionTypes.CHECK_AUTH]({ commit, dispatch, state }) {
    if (state.isAuthenticated && !navigator.onLine) {
      return new Promise((resolve) => {
        resolve(state.user);
      });
    } else if (state.isAuthenticated) {
      return new Promise((resolve, reject) => {
        apiService.post(`${API_PATH}/check`)
          .then(({ data }) => {
            const user = { ...data };
            delete user.token;
            delete user.acr;

            commit(actionTypes.SET_AUTH, { user, check: true });
            dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
            resolve(user);
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              dispatch(actionTypes.LOGOUT);
              Vue.popup("messages.sessionEnded", "error");
            } else {
              // TODO: show network connection error visual
            }
            reject(error);
          });
      });
    } else {
      // commit(actionTypes.PURGE_AUTH);
    }
  },
  [actionTypes.HANDSHAKE_SERVER]() {
    apiService.get("handshake");
  },

  [actionTypes.HEARTBEAT]({ dispatch, rootState }) {
    apiActions.action(
      `${API_PATH}/heartbeat`,
      rootState.ui.reactivityParams,
      null,
      null,
      {
        silent: true,
        onSuccess: () => {
          dispatch(`ui/${REFRESH_REACTIVITY_PARAMS}`, null, { root: true });
        }
      });
  },
  [actionTypes.REGISTER]({ commit, dispatch }, params) {
    params = {
      ...params,
      roles: ["CUSTOMER"],
      typeName: "CUSTOMER",
    };

    const { registrationType } = params;

    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/register`, params)
        .then(({ data }) => {
          const user = { ...data };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          resolve(user);
        })
        .catch(({ response }) => {
          if (registrationType === "phone") {
            Vue.popup("messages.errorConflictPhone", "error");
          } else {
            Vue.popup("messages.errorConflictUser", "error");
          }
          reject(response);
        });
    });
  },
  async [actionTypes.SAVE_PROFILE]({ commit, dispatch }, params) {
    return apiService.patch(`${API_PATH}/profile`, params)
      .then(({ data }) => {
        const user = { ...data };
        delete user.token;
        delete user.acr;

        commit(actionTypes.SET_AUTH, { user, check: true });
        Vue.popup("messages.updateDone", "success");
      })
      .catch((error) => {
        Vue.popup("messages.operationError", "error");
        throw new Error(error);
      });
  },
  [actionTypes.COMPLETE_REGISTRATION]({ commit, dispatch }, params) {
    const { code, type } = params;
    return new Promise((resolve, reject) => {
      // for testing only
      apiService.post(`${API_PATH}/confirm.${type}/${code}`)
        .then(({ data }) => {
          const user = { ...data };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          resolve(user);
        })
        .catch(() => {
          if (type === "pin") {
            Vue.popup(
              "messages.invalidConfirmationPin",
              "error"
            );
          } else {
            Vue.popup(
              "messages.invalidConfirmationCode",
              "error"
            );
          }
          reject();
        });
    });
  },
  [actionTypes.PASSWORD_RESET]({ dispatch }, email) {
    const url = `${API_PATH}/password.reset`;
    return apiService.patch(url, email).then(({ data }) => {
      Vue.popup("messages.checkEmailInbox", "info");
      return data;
    });
  },
  [actionTypes.RESET_PASSWORD]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      // for testing only
      apiService.post(`${API_PATH}/reset.password`, params)
        .then(({ data }) => {
          const user = { ...data };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          Vue.popup("messages.passwordUpdated", "success");
          resolve(user);
        })
        .catch(() => {
          Vue.popup("messages.passwordUpdatedFailed", "error");
          reject();
        });
    });
  },
  [actionTypes.PIN]({ dispatch }, email) {
    const url = `${API_PATH}/pin`;
    return apiService.patch(url, email).then(({ data }) => {
      Vue.popup("messages.checkEmailInbox", "info");
      return data;
    });
  },
  [actionTypes.SEND_PIN_CODE]({ dispatch }, params) {
    const url = `${API_PATH}/pin.code`;
    return apiService.patch(url, params).then(({ data }) => {
      Vue.popup("messages.checkSms", "info");
      return data;
    });
  },
  [actionTypes.REQUEST_LOGIN_PIN]({ dispatch }, phone) {
    const url = `${API_PATH}/login.pin/${phone}`;
    return new Promise((resolve, reject) => {
      apiService.post(url)
        .then(({ data }) => {
          Vue.popup("messages.checkSms", "info");
          resolve(data);
        })
        .catch(() => {
          Vue.popup("messages.operationError", "error");
          reject();
        });
    });
  },
  [actionTypes.CHANGE_EMAIL]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      // for testing only
      apiService.post(`${API_PATH}/change.password`, params)
        .then(({ data }) => {
          const user = { ...data };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          Vue.popup("messages.updateDone", "success");
          resolve(user);
        })
        .catch(() => {
          Vue.popup("messages.passwordUpdatedFailed", "error");
          reject();
        });
    });
  },
  [actionTypes.CHANGE_PHONE]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      apiService.post(`${API_PATH}/change.phone`, params)
        .then(() => {
          Vue.popup("messages.updateDone", "success");
          dispatch(actionTypes.LOGOUT);
          resolve();
        })
        .catch(() => {
          Vue.popup("messages.phoneUpdatedFailed", "error");
          reject();
        });
    });
  },
  async [actionTypes.SELECT_USER_REGION]({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      // for testing only
      apiService.patch(`${API_PATH}/select.region/${id}`)
        .then(({ data }) => {
          const user = { ...data };
          delete user.acr;

          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, data.acr, { root: true });
          Vue.popup("messages.updateDone", "success");
          resolve(user);
        })
        .catch(() => {
          Vue.popup("messages.updateNotSuccessful", "error");
          reject();
        });
    });
  },
  [actionTypes.UPDATE_USER_STATE]({ commit }, params) {
    commit(actionTypes.UPDATE_USER_STATE, params)
  },
};

const mutations = {
  [actionTypes.SET_AUTH](state, params) {
    const { user, check } = params;
    state.isAuthenticated = true;
    if (!check) {
      window.localStorage.setItem("type", user.userType);
      state.user = user;
    } else {
      state.user = {
        ...state.user,
        name: user.name,
        email: user.email,
        phone: user.phone,
        typeName: user.userType,
        statusName: user.statusName,
        regionId: user.regionId,
        storeId: user.storeId,
        activeAddress: user.activeAddress,
        roles: user.roles,
        assignedStoreIds: user.assignedStoreIds,
      };
      window.localStorage.removeItem("loginPhone");
      window.localStorage.removeItem("registerPhone");
    }
  },
  [actionTypes.UPDATE_USER_STATE](state, payload) {
    state.user = {
      ...state.user,
      ...payload,
    };
},
  [actionTypes.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

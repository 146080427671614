import Vue from "vue";
import Router from "vue-router";
import { authorizationService } from "@/services";

// Layouts
const GenericLayout = () => import("@/common/layouts/GenericLayout");
const AppLayout = () => import("@/common/layouts/AppLayout");
const DefaultLayout = () => import("@/common/layouts/DefaultLayout");

// Views
const About = () => import("@/views/about/About");
const Intro = () => import("@/views/about/Intro");

const Stores = () => import("@/views/stores/Stores");
const StoresSearch = () => import("@/views/stores/StoresSearch");
const OpenStore = () => import("@/views/stores/OpenStore");
const StoreSearch = () => import("@/views/stores/StoreSearch");
const OpenSection = () => import("@/views/stores/OpenSection");
const OpenProduct = () => import("@/views/stores/OpenProduct");
const Cart = () => import("@/views/orders/Cart");
const Checkout = () => import("@/views/orders/Checkout");
const Favorites = () => import("@/views/stores/Favorites");

const Orders = () => import("@/views/orders/Orders");
const Order = () => import("@/views/orders/Order");

const Offers = () => import("@/views/offers/Offers");
const Settings = () => import("@/views/settings/Settings");
const Profile = () => import("@/views/settings/Profile");
const BusinessSettings = () => import("@/views/settings/BusinessSettings");
const ManagerStats = () => import("@/views/manager/ManagerStats");
const ManagedStores = () => import("@/views/manager/ManagedStores");
const ManagerStores = () => import("@/views/manager/ManagerStores");
const AdminStoreProfile = () => import("@/views/admin/units/AdminStoreProfile");
const ManagerStoreProfile = () => import("@/views/manager/ManagerStoreProfile");
const ProductsList = () => import("@/views/admin/products/ProductsList");

const SavedAddresses = () => import("@/views/settings/SavedAddresses");
const Address = () => import("@/views/settings/Address");
const ChangeEmail = () => import("@/views/settings/ChangeEmail");
const ChangePhone = () => import("@/views/settings/ChangePhone");
const ChangePassword = () => import("@/views/settings/ChangePassword");

const SelectedRegion = () => import("@/views/settings/SelectedRegion");
const AdminRegions = () => import("@/views/admin/units/AdminRegions");
const RegionStores = () => import("@/views/admin/units/RegionStores");
const RegionEditor = () => import("@/views/admin/units/RegionEditor");
const CustomerRegion = () => import("@/views/admin/customers/CustomerRegion");
const StoreSections = () => import("@/views/admin/units/StoreSections");
const SectionForm = () => import("@/views/admin/units/SectionForm");
const SectionCategories = () => import("@/views/admin/units/SectionCategories");
const CategoryForm = () => import("@/views/admin/units/CategoryForm");

// const StoreEditor = () => import("@/views/admin/units/StoreEditor");

const Dashboards = () => import("@/views/admin/dashboards/Dashboards");
const DataTypes = () => import("@/views/admin/data.types/DataTypes");
const NotificationsOptions = () => import("@/views/admin/notifications/NotificationsOptions");
const ManagerStoreOffers = () => import("@/views/manager/ManagerStoreOffers");
const OrdersManager = () => import("@/views/admin/orders/OrdersManager");
const OrderDetails = () => import("@/views/orders/OrderDetails");
const ProductForm = () => import("@/views/admin/products/ProductForm");

// Drivers
const ActiveDelivery = () => import("@/views/delivery/ActiveDelivery");

// View Access Control
const Users = () => import("@/views/admin/ac/Users");
const UserEditor = () => import("@/views/admin/ac/UserEditor");
const RoleEditor = () => import("@/views/admin/ac/RoleEditor");
const Roles = () => import("@/views/admin/ac/Roles");
const Types = () => import("@/views/settings/Types");
const TypeEditor = () => import("@/views/settings/TypeEditor");

// general
// const MapLocation = import("@/views/general/MapLocation");

// Views - Errors
const Page404 = () => import("@/views/errors/Page404");
const Page500 = () => import("@/views/errors/Page500");

// Views - Authentication
const Login = () => import("@/views/auth/Login");
const PinLogin = () => import("@/views/auth/PinLogin");
// const Register = () => import("@/views/auth/Register");
const PhoneRegister = () => import("@/views/auth/PhoneRegister");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const PasswordReset = () => import("@/views/auth/PasswordReset");
const ResetPassword = () => import("@/views/auth/ResetPassword");
const EmailConfirmation = () => import("@/views/auth/EmailConfirmation");
const PinConfirmation = () => import("@/views/auth/PinConfirmation");

function configRoutes() {
  const accountType = process.env.VUE_APP_CLIENT_TYPE || window.localStorage.getItem("type") || "*";

  const adminViews = accountType === "ADMIN" ? [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      component: GenericLayout,
      children: [
        {
          path: "dashboards",
          name: "admin.dashboards",
          component: Dashboards,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.dashboard", disabled: true },
            ],
            resource: "admin.dashboard",
          },
        },
        {
          path: "regions",
          name: "admin.regions",
          component: AdminRegions,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", disabled: true },
            ],
            resource: "admin.regions",
          },
        },
        {
          path: "region/:regionId",
          name: "admin.region",
          component: RegionEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions" },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.region",
          },
        },
        {
          path: "region/:regionId/stores",
          name: "admin.region.stores",
          component: RegionStores,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", link: "/#/admin/regions" },
              { name: "app.route.stores", disabled: true },
            ],
            resource: "admin.stores",
            back: { name: "regions", params: { regionId: "regionId" } },
          },
        },
        {
          path: "/region/:regionId/store/:storeId",
          name: "admin.store.profile",
          component: AdminStoreProfile,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "app.route.stores", disabled: true },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.store.profile",
            back: { name: "region.stores", params: { regionId: "regionId" } },
          },
        },
        {
          path: "region/:regionId/store/:storeId/sections",
          name: "admin.store.sections",
          component: StoreSections,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", link: "/#/admin/regions" },
              { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
              { name: "app.route.sections", disabled: true },
            ],
            resource: "admin.store.sections",
            back: { name: "region.stores", params: { regionId: "regionId" } },
          },
        },
        {
          path: "region/:regionId/store/:storeId/section/:sectionId",
          name: "admin.store.section",
          component: SectionForm,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", link: "/#/admin/regions" },
              { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
              { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
              { name: "app.route.section", disabled: true },
            ],
            resource: "admin.store.section",
            back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
          },
        },
        {
          path: "region/:regionId/store/:storeId/section/:sectionId/categories",
          name: "admin.store.categories",
          component: SectionCategories,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", link: "/#/admin/regions" },
              { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
              { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
              { name: "app.route.categories", disabled: true },
            ],
            resource: "admin.store.categories",
            back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
          },
        },
        {
          path: "region/:regionId/store/:storeId/section/:sectionId/category/:categoryId",
          name: "admin.store.category",
          component: CategoryForm,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.regions", link: "/#/admin/regions" },
              { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
              { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
              { name: "app.route.categories", link: "/#/admin/regions/:regionId/stores/:storeId/secion/:sectionId" },
              { name: "app.route.category", disabled: true },
            ],
            resource: "admin.store.category",
            back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
          },
        },
        {
          path: "customers",
          name: "admin.customers",
          component: Users,
          meta: {
            breadcrumbs: [
              { name: "admin.route.management" },
              { name: "admin.route.customers", disabled: true },
            ],
            resource: "admin.customers",
          },
          props: {
            type: ["CUSTOMER", "TESTER"],
            editRoute: "admin.customer",
          },
        },
        {
          path: "customer/:userId",
          name: "admin.customer",
          component: UserEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.management" },
              { name: "admin.route.customers" },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.customer",
            back: { name: "customers" },
          },
        },
        {
          path: "customer/:userId/region",
          name: "admin.customer.region",
          component: CustomerRegion,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.customers", link: "/#/admin/customers" },
              { name: "app.route.regions", disabled: true },
            ],
            resource: "admin.customers",
            back: { name: "customers" },
          },
        },
        {
          path: "store/:storeId/products",
          name: "admin.store.products",
          component: ProductsList,
          props: { target: "admin.store.product" },
          meta: {
            breadcrumbs: [
              { name: "admin.route.management" },
              { name: "admin.route.products", disabled: true },
            ],
            resource: "admin.store.products",
          },
        },
        {
          path: "store/:storeId/product/:productUuid",
          name: "admin.store.product",
          component: ProductForm,
          meta: {
            breadcrumbs: [
              { name: "admin.route.management" },
              { name: "admin.route.products", disabled: true },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.store.product",
            back: { name: "admin.store.products" },
          },
        },
        {
          path: "order/:orderId",
          name: "admin.order",
          component: OrderDetails,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.orders", disabled: true },
              { name: "general.details", disabled: true, dynamic: true },
            ],
            resource: "admin.order",
          },
        },
        {
          path: "notifications",
          name: "admin.notifications",
          component: NotificationsOptions,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.notifications", disabled: true },
            ],
            resource: "admin.notifications",
          },
        },
        {
          path: "users",
          name: "admin.users",
          component: Users,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.users", disabled: true },
            ],
            resource: "admin.users",
          },
          props: {
            type: [
              "SUPER-ADMIN",
              "ADMIN",
              "STORE-MANAGER",
              "USER",
              "MERCHANT",
              "DRIVER",
              "ORDER-HANDLER",
              "TESTER",
            ],
            editRoute: "admin.user",
          },
        },
        {
          path: "user/:userId",
          name: "admin.user",
          component: UserEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.users" },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.user",
            back: { name: "users" },
          },
        },
        {
          path: "user/:userId/roles",
          name: "user.roles",
          component: UserEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.users" },
              { name: "route.roles", disabled: true },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            // ac resource name
            resource: "admin.user.roles",
            // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
          },
        },
        {
          path: "user/:userId/stores",
          name: "admin.user.stores",
          component: UserEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.users" },
              { name: "route.stores", disabled: true },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            // ac resource name
            resource: "admin.user.stores",
            // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
            back: { name: "users" }
          },
        },
        {
          path: "roles",
          name: "admin.roles",
          component: Roles,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.roles", disabled: true },
            ],
            resource: "admin.roles",
          },
        },
        {
          path: "role/:roleId",
          name: "admin.role",
          component: RoleEditor,
          meta: {
            breadcrumbs: [
              { name: "admin.route.admin" },
              { name: "admin.route.roles" },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "admin.role",
            back: { name: "roles" },
          },
        },
        {
          path: "types",
          name: "admin.types",
          component: Types,
          meta: {
            breadcrumbs: [
              { name: "app.route.settings" },
              { name: "admin.route.dataTypes", disabled: true },
              {
                dynamic: true,
                skipTranslate: false,
              },
            ],
            resource: "admin.types",
          },
        },
        {
          path: "types/:route",
          name: "admin.type",
          component: TypeEditor,
          meta: {
            breadcrumbs: [
              { name: "app.route.settings" },
              { name: "admin.route.dataTypes", disabled: true },
            ],
            resource: "admin.types",
          },
        },
      ],
    }
  ]: [];

  const customerViews = ["ADMIN", "CUSTOMER", "TESTER", "*"].includes(accountType) ? [
    {
      path: "stores",
      name: "stores",
      component: Stores,
      meta: {
        breadcrumbs: [{ name: "app.route.stores" }],
        resource: "stores",
      },
    },
    {
      path: "store/:storeId/:store",
      name: "store",
      component: OpenStore,
      meta: {
        breadcrumbs: [
          { name: "app.route.stores", link: "/#/stores" },
          { name: "app.route.store", dynamic: true },
        ],
        resource: "store",
      },
    },
    {
      path: "store/:storeId/:store/section/:sectionId/:section",
      name: "section",
      component: OpenSection,
      meta: {
        breadcrumbs: [
          { name: "app.route.stores", link: "/#/stores" },
          {
            name: "app.route.store", link: "/#/store/:storeId/:store",
            nameParam: "store",
            dynamic: true
          },
          {
            name: "app.route.section",
            nameParam: "section",
            disabled: true,
            dynamic: true,
          },
        ],
        resource: "section",
      },
    },
    {
      path: "store/:storeId/:store/:sectionId/:section/product/:productId",
      name: "product",
      component: OpenProduct,
      meta: {
        breadcrumbs: [
          { name: "app.route.stores", link: "/#/stores" },
          { name: "app.route.store", link: "/#/store/:storeId", dynamic: true },
          { name: "app.route.section", link: "/#/section/:storeId/:sectionId/:section", dynamic: true },
          { name: "app.route.category", link: "/#/section/:storeId/:sectionId/:section/#cat-:categoryId", dynamic: true },
          {
            name: "app.route.product",
            dynamic: true,
            disabled: true,
            skipTranslate: true,
          },
        ],
        resource: "product",
        back: {
          name: "section",
          params: { storeId: "storeId", store: "store", sectionId: "sectionId", section: "section" },
        },
      },
    },
    {
      path: "search/:storeId/:store",
      name: "products.search",
      component: StoreSearch,
      meta: {
        resource: "store",
        back: { name: "store", params: { storeId: "storeId", store: "store" } },
      },
    },
    {
      path: "stores/search",
      name: "stores.search",
      component: StoresSearch,
      meta: {
        resource: "stores",
        back: { name: "stores" },
      }
    },
    {
      path: "cart/:storeId/:store",
      name: "cart",
      component: Cart,
      meta: {
        breadcrumbs: [{ name: "app.route.cart", disabled: true }],
        resource: "cart",
        back: { name: "store", params: { storeId: "storeId", store: "store" } },
      },
    },
    {
      path: "checkout/:storeId/:store",
      name: "checkout",
      component: Checkout,
      meta: {
        breadcrumbs: [
          { name: "app.route.cart", link: "/#/cart/:storeId" },
          { name: "app.route.checkout", disabled: true }],
        resource: "cart",
        back: { name: "cart", params: { storeId: "storeId", store: "store" } },
      },
    },
    {
      path: "orders",
      name: "orders",
      component: Orders,
      meta: {
        breadcrumbs: [{ name: "app.route.orders", disabled: true }],
        resource: "orders",
      },
    },
    {
      path: "order/:uuid",
      name: "order",
      component: Order,
      props: {
        driver: true,
        support: true,
        // history: true,
        audiance: "Driver",
      },
      meta: {
        breadcrumbs: [{ name: "app.route.orders", disabled: true }],
        resource: "order",
        back: { name: "orders" },
      },
    },
    {
      path: "store/:storeId/:store/favorites",
      name: "favorites",
      component: Favorites,
      meta: {
        breadcrumbs: [
          { name: "app.route.stores", link: "/#/stores" },
          {
            name: "app.route.store", link: "/#/store/:storeId/:store",
            nameParam: "store",
            dynamic: true
          },
          { name: "app.route.favorites", disabled: true }
        ],
        resource: "favorites",
        back: { name: "store", params: { storeId: "storeId", store: "store" } },
      },
    },
    {
      path: "offers",
      name: "offers",
      component: Offers,
      meta: {
        breadcrumbs: [
          { name: "app.route.shopping", link: "/#/store" },
          { name: "app.route.offers", disabled: true },
        ],
        resource: "offers",
        back: { name: "store", params: { storeId: "storeId", store: "store" } },
      },
    },
  ]: [];

  const managerViews =  ["ADMIN", "MERCHANT", "MANAGER", "ORDER-HANDLER", "TESTER"].includes(accountType) ? [
    {
      path: "/manager",
      redirect: "/manager/stats",
      component: GenericLayout,
      children: [
        {
          path: "stats",
          name: "manager.stats",
          component: ManagerStats,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.stats", disabled: true },
            ],
            resource: "manager.stats",
          },
        },
        {
          path: "stores",
          name: "manager.stores",
          component: ManagerStores,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.storeProfiles", disabled: true },
            ],
            resource: "manager.stores",
            back: { name: "manager.stats" },
          },
        },
        {
          path: "store/:storeId/profile",
          name: "manager.store.profile",
          component: ManagerStoreProfile,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.storeProfile", disabled: true },
            ],
            resource: "manager.store.profile",
            back: { name: "manager.stats" },
          },
        },
        {
          path: "store/:storeId/sections",
          name: "manager.store.sections",
          component: StoreSections,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.stores", link: "/#/stores/profies" },
              { name: "app.route.sections", disabled: true },
            ],
            resource: "manager.store.sections",
            back: { name: "manager.stores" },
          },
        },
        {
          path: "store/:storeId/sections/:sectionUuid",
          name: "manager.store.section",
          component: SectionForm,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.stores", link: "/#/manager/store" },
              { name: "app.route.store", link: "/#/manager/store/:storeId" },
              { name: "app.route.sections", link: "/#/manager/store/:storeId/sections" },
              { name: "app.route.section", disabled: true },
            ],
            resource: "manager.store.section",
            back: { name: "manager.store.sections", params: { storeId: "storeId" } },
          },
        },
        {
          path: "store/:storeId/products",
          name: "manager.store.products",
          component: ProductsList,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.products", disabled: true },
            ],
            resource: "manager.store.products",
            back: { name: "manager.stores" },
          },
        },

        {
          path: "store/:storeId/section/:sectionUuid/categories",
          name: "manager.store.categories",
          component: SectionCategories,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.stores", link: "/#/manager/store" },
              { name: "app.route.store", link: "/#/manager/store/:storeId" },
              { name: "app.route.sections", link: "/#/manager/store/:storeId/sections" },
              { name: "app.route.section", link: "/#/manager/store/:storeId/sections/:sectionUuid" },
              { name: "app.route.categories", disabled: true },
            ],
            resource: "manager.store.categories",
            back: { name: "manager.store.sections", params: { storeId: "storeId" } },
          },
        },
        {
          path: "store/:storeId/section/:sectionUuid/category/:categoryUuid",
          name: "manager.store.category",
          component: CategoryForm,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.stores", link: "/#/manager/store" },
              { name: "app.route.store", link: "/#/manager/store/:storeId" },
              { name: "app.route.sections", link: "/#/manager/store/:storeId/sections" },
              { name: "app.route.section", link: "/#/manager/store/:storeId/sections/:sectionUuid" },
              { name: "app.route.categories", link: "/#/manager/store/:storeId/sections/:sectionUuid/categories" },
              { name: "app.route.category", disabled: true },
            ],
            resource: "manager.store.category",
            back: { name: "manager.store.categories", params: { storeId: "storeId", sectionUuid: "sectionUuid" } },
          },
        },
        // {
        //   path: "store/products",
        //   name: "store.products",
        //   component: ManagedStores,
        //   props: { target: "manager.store.products" },
        //   meta: {
        //     breadcrumbs: [
        //       { name: "app.route.manager", disabled: true },
        //       { name: "app.route.managedStores", disabled: true },
        //     ],
        //     resource: "store.products",
        //     back: { name: "manager.stats" },
        //   },
        // },
        // {
        //   path: "store/:storeId/products",
        //   name: "store.products.list",
        //   component: ProductsList,
        //   props: { target: "store.product" },
        //   meta: {
        //     breadcrumbs: [
        //       { name: "app.route.manager", disabled: true },
        //       { name: "app.route.products", disabled: true },
        //     ],
        //     resource: "store.products",
        //     back: { name: "manager.stats" },
        //   },
        // },
        {
          path: "store/:storeId/product/:productUuid",
          name: "manager.store.product",
          component: ProductForm,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.products", disabled: true },
              { name: "general.new", disabled: true, dynamic: true },
            ],
            resource: "manager.store.product",
            back: { name: "manager.store.products", params: { storeId: "storeId" } },
          },
        },
        {
          path: "store/dispatchers",
          name: "manager.store.dispatchers",
          component: ManagedStores,
          props: { target: "manager.store.dispatcher" },
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.managedStores", disabled: true },
            ],
            resource: "manager.store.dispatchers",
            back: { name: "manager.stats" },
          },
        },
        {
          path: "store/:storeId/dispatcher",
          name: "manager.store.dispatcher",
          component: OrdersManager,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager", disabled: true },
              { name: "app.route.storeDispatcher", disabled: true },
            ],
            resource: "manager.store.dispatchers",
          },
        },
        {
          path: "store/offers",
          name: "manager.store.offers",
          component: ManagerStoreOffers,
          meta: {
            breadcrumbs: [
              { name: "app.route.manager" },
              { name: "app.route.offers", disabled: true },
            ],
            resource: "manager.store.offers",
          },
        },
      ],
    }
  ]: [];

  const deliveryViews = ["ADMIN", "DRIVER", "TESTER"].includes(accountType) ? [
    {
      path: "/delivery",
      name: "delivery",
      redirect: "/delivery/active",
      component: GenericLayout,
      children: [
        {
          path: "active",
          name: "delivery.active",
          component: ActiveDelivery,
          meta: {
            breadcrumbs: [
              { name: "driver.route.activeDelivery", disabled: true },
            ],
            resource: "delivery.active",
          },
        },
        {
          path: ":uuid",
          name: "delivery.order",
          component: Order,
          props: {
            customer: true,
            support: true,
            // history: true,
            audiance: "Customer"
          },
          meta: {
            breadcrumbs: [{ name: "app.route.orders", disabled: true }],
            resource: "delivery.order",
            back: { name: "delivery.active" },
          },
        },
      ],
    },
  ]: [];

  return [
    {
      path: "/",
      redirect: "/stores",
      name: "default",
      component: AppLayout,
      children: [
        ...customerViews,
        //public
        // {
        //   path: "map-location",
        //   name: "map.location",
        //   component: MapLocation,
        // },

        //manager
        ...managerViews,

        // admin
        ...adminViews,

        //delivery
        ...deliveryViews,

        //settings
        {
          path: "/settings",
          redirect: "/settings/menu",
          component: GenericLayout,
          children: [
            {
              path: "menu",
              name: "settings",
              component: Settings,
              meta: {
                breadcrumbs: [{ name: "app.route.settings", disabled: true }],
                resource: "settings",
                back: { name: "stores" },
              },
            },
            {
              path: "profile",
              name: "profile",
              component: Profile,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.profile", disabled: true },
                ],
                resource: "profile",
                back: { name: "settings" },
              },
            },
            {
              path: "region",
              name: "region",
              component: SelectedRegion,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.regions", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "addresses",
              name: "addresses",
              component: SavedAddresses,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.savedAddresses", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "address/:addressId",
              name: "address",
              component: Address,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.address", disabled: true },
                ],
                resource: "settings.address",
                back: { name: "addresses" },
              },
            },
            {
              path: "change-email",
              name: "change-email",
              component: ChangeEmail,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changeEmail", disabled: true },
                ],
                resource: "settings.email",
                back: { name: "settings" },
              },
            },
            {
              path: "change-phone",
              name: "change-phone",
              component: ChangePhone,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changePhone", disabled: true },
                ],
                resource: "settings.phone",
                back: { name: "settings" },
              },
            },
            {
              path: "change-password",
              name: "change-password",
              component: ChangePassword,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changePassword", disabled: true },
                ],
                resource: "settings.password",
                back: { name: "settings" },
              },
            },
          ],
        },

        //general
        {
          path: "intro",
          name: "intro",
          component: Intro,
          meta: {
            breadcrumbs: [{ name: "app.route.intro" }],
          },
        },
        {
          path: "about",
          name: "about",
          component: About,
          meta: {
            breadcrumbs: [{ name: "app.route.about" }],
          },
        },
      ],
    },
    {
      path: "/auth",
      redirect: "/auth/login",
      component: DefaultLayout,
      children: [
        {
          path: "login",
          name: "login",
          component: PinLogin,
        },
        {
          path: "alt-login",
          name: "alt-login",
          component: Login,
        },
        {
          path: "register",
          name: "register",
          component: PhoneRegister,
        },
        {
          path: "email/:code?",
          name: "email",
          component: EmailConfirmation,
        },
        {
          path: "pin/:code?",
          name: "pin",
          component: PinConfirmation,
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          component: ForgotPassword,
        },
        {
          path: "password-reset",
          name: "password-reset",
          component: PasswordReset,
        },
        {
          path: "reset-password/:code?",
          name: "reset-password",
          component: ResetPassword,
        },
      ],
    },
    {
      path: "/error",
      redirect: "/error/404",
      name: "error",
      component: GenericLayout,
      children: [
        {
          path: "404",
          name: "error.404",
          component: Page404,
        },
        {
          path: "500",
          name: "error.500",
          component: Page500,
        },
      ],
    },
  ];
}

const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

export default router;

Vue.use(Router);
